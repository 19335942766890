import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid, Modal, Paper } from "@material-ui/core";
import useStyles from "../styles";
import { RestartAlt } from "@mui/icons-material";
import QuickViewGraphs from "./QuickViewGraphs";
import * as api from "../../../api";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../../services/service";
import moment from "moment";
import ApiCall from "../../../api/ApiCall";
import { AllMISDashboardRoutes } from "../../../api/Routes";
import PerformanceCounts from "./PerformanceCounts";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";
import Skeleton from "@mui/material/Skeleton";
import { AuthContext } from "../../../contextAPI/context";
import { ExportJsonCsv } from "react-export-json-csv";
import { Close } from "@material-ui/icons";

const QuickViewNew = () => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { plant } = authState;
  const [loader, setloader] = useState(false);
  const [allCategory, setallCategory] = useState([]);
  const [isFilterReset, setIsFilterReset] = useState(false);

  // for start date and end date
  const currentDate = moment().format("YYYY-MM-DD");
  const currentMonthStartDate = moment(currentDate)
    .startOf("month")
    .format("YYYY-MM-DD");
  const currentMonthEndDate = moment(currentDate)
    .endOf("month")
    .format("YYYY-MM-DD");

  const [startDate, setstartDate] = useState(currentMonthStartDate);
  const [endDate, setendDate] = useState(
    localStorage.getItem("toDateforQuickView") || currentDate
  );

  // for category wise
  const [categoryComplaintGraphData, setCategoryComplaintGraphData] = useState(
    []
  );
  const [loaderCategoryWise, setloaderCategoryWise] = useState(false);
  const [categoryWiseStatus, setcategoryWiseStatus] = useState("all");

  // for vendor wise graph
  const [
    monthlyPendingComplaintGraphData,
    setMonthlyPendingComplaintGraphData,
  ] = useState([]);
  const [loaderVendorWise, setloaderVendorWise] = useState(false);

  // for sla graph
  const [mixedComplaintsGraphData, setMixedComplaintsGraphData] = useState([]);
  const [loaderSlaData, setloaderSlaData] = useState(false);

  // for sector graph
  const [sectorGraphData, setSectorGraphData] = useState([]);
  const [loaderSectorGraph, setloaderSectorGraph] = useState(false);
  const [sectorWiseStatus, setSectorWiseStatus] = useState("all");

  //for most raised category
  const [mostRaisedCategory, setMostRaisedCategory] = useState([]);
  const [mostRaisedCategoryLoader, setMostRaisedCategoryLoader] =
    useState(false);
  const [mostRaisedCategoryPlant, setMostRaisedCategoryPlant] = useState([]);
  const [mostRaisedCategoryPlantLoader, setMostRaisedCategoryPlantLoader] =
    useState(false);

  //for most raised property
  const [mostRaisedProperty, setMostRaisedProperty] = useState([]);

  const [vendorPerformanceDataLoader, setVendorPerformanceDataLoader] =
    useState(false);
  const [vendorPerformanceData, setVendorPerformanceData] = useState([]);
  const [vendorPerformanceComplaintsData, setVendorPerformanceComplaintsData] =
    useState([]);
  const [complaintCounts, setComplaintCounts] = useState(null);
  const [allVendors, setAllVendors] = useState([]);
  const vendorsId = allVendors?.map((vendor) => vendor._id);
  // for download csv
  const [downloadCsv, setDownloadCsv] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState(null);
  const [graphTypeName, setGraphTypeName] = useState("");

  // for SLA zone
  const [slaZoneGraphData, setSlaZoneGraphData] = useState([]);
  const [slaZoneLoader, setSlaZoneLoader] = useState(false);

  const [selectCategory, setSelectCategory] = useState("");
  const [categoryName, setCategoryName] = useState("All");

  const handleCategoryClick = (categoryId) => {
    setSelectCategory((prev) => {
      if (prev.includes(categoryId)) {
        return prev.filter((id) => id !== categoryId);
      } else {
        return [...prev, categoryId];
      }
    });
  };

  const handleGetVendors = async () => {
    try {
      let sendData = {
        pageLimit: 50,
        societyId: fetchSocietyId(),
        pageNumber: 1,
        searchKey: "",
        approvalStatus: "approved",
        isSubVendor: false,
      };
      const res = await api.getAllVendor(sendData);
      if (res?.data?.success) {
        setAllVendors(res?.data?.vendor?.vendor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetVendors();
  }, []);

  const handleReset = (isReset) => {
    localStorage.removeItem("fromDateforQuickView");
    localStorage.removeItem("toDateforQuickView");
    setstartDate(currentMonthStartDate);
    setendDate(currentMonthEndDate);
    setIsFilterReset(isReset);
  };

  useEffect(() => {
    if (isFilterReset) {
      if (
        startDate === currentMonthStartDate &&
        endDate === currentMonthEndDate
      ) {
        handleFetchData();
      }
    }
  }, [isFilterReset]);

  // for vendor performance
  const getQuickViewData = async () => {
    setVendorPerformanceDataLoader(true);
    setTimeout(() => {
      setVendorPerformanceDataLoader(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    let res = await ApiCall(
      "post",
      AllMISDashboardRoutes.misQuickViewOtherData,
      data
    );
    if (res?.success) {
      setVendorPerformanceData(res?.data);
      setVendorPerformanceDataLoader(false);
    } else {
      setVendorPerformanceDataLoader(false);
    }
  };

  useEffect(() => {
    getQuickViewData();
  }, [startDate, endDate]);

  const getQuickViewComplaintsData = async () => {
    const data = {
      societyId: fetchSocietyId(),
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      category: selectCategory,
    };
    let res = await ApiCall(
      "post",
      AllMISDashboardRoutes.societyWiseComplaintsData,
      data
    );
    if (res?.success) {
      setVendorPerformanceComplaintsData(res?.data);
    } else {
    }
  };

  useEffect(() => {
    getQuickViewComplaintsData();
  }, [selectCategory]);

  // get complaint category
  const getComplaintCategory = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        pageNumber: 1,
        searchKey: "",
      };
      const res = await api.getComplaintCategory(sendData);
      if (res?.data?.success) {
        setallCategory(res.data.category.complaintCategory);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    getComplaintCategory();
  }, []);

  // complaint count category wise
  const handleGetComplaintCounts = async () => {
    const data = {
      filters: {
        society: fetchSocietyId(),
        category: selectCategory,
      },
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.getCategoryWiseComplaintCounts,
      data
    );
    if (res?.success) {
      setComplaintCounts(res?.feedback);
    }
  };

  // useEffect(() => {
  //   handleGetComplaintCounts();
  // }, [selectCategory]);

  // category wise complaint data
  const handleGetCategoryWiseData = async () => {
    setloaderCategoryWise(true);
    setTimeout(() => {
      setloaderCategoryWise(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      category: selectCategory,
      complaintStatus: localStorage.getItem("stausForCategoryWise")
        ? localStorage.getItem("stausForCategoryWise")
        : "all",
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.misCategoryWiseGraphData,
      data
    );
    if (res?.success) {
      setIsFilterReset(false);
      setCategoryComplaintGraphData(res?.caetegoryWiseData);
      setloaderCategoryWise(false);
    } else {
      setloaderCategoryWise(false);
    }
  };

  useEffect(() => {
    handleGetCategoryWiseData();
  }, [categoryWiseStatus, selectCategory]);

  // vendor graph data
  const handleGetVendorWiseGraphData = async () => {
    setloaderVendorWise(true);
    setTimeout(() => {
      setloaderVendorWise(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      complaintStatus: "assigned",
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      vendors: vendorsId,
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.misVendorWiseGraphData,
      data
    );
    if (res?.success) {
      setIsFilterReset(false);
      setMonthlyPendingComplaintGraphData(res?.vendorWiseData);
      setloaderVendorWise(false);
    } else {
      setloaderVendorWise(false);
    }
  };

  useEffect(() => {
    if (allVendors?.length > 0) {
      handleGetVendorWiseGraphData();
    }
  }, [allVendors, selectCategory]);

  // sla graph data
  const handleGetSlaGraphData = async () => {
    setloaderSlaData(true);
    setTimeout(() => {
      setloaderSlaData(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.misSlaGraphData,
      data
    );
    if (res?.success) {
      setIsFilterReset(false);
      setMixedComplaintsGraphData(res?.slaData);
      setloaderSlaData(false);
    } else {
      setloaderSlaData(false);
    }
  };

  useEffect(() => {
    handleGetSlaGraphData();
  }, [selectCategory]);

  // sector wise complaints data
  const hendleGetSectorWiseData = async () => {
    setloaderSectorGraph(true);
    setTimeout(() => {
      setloaderSectorGraph(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      complaintStatus: localStorage.getItem("stausForSectorWise")
        ? localStorage.getItem("stausForSectorWise")
        : "assigned",
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.misSectorWiseGraphData,
      data
    );
    if (res?.success) {
      setIsFilterReset(false);
      setSectorGraphData(res?.sectorData);
      setloaderSectorGraph(false);
    } else {
      setloaderSectorGraph(false);
    }
  };

  useEffect(() => {
    hendleGetSectorWiseData();
  }, [sectorWiseStatus, selectCategory]);

  // SLA zone
  const handleGetSlaZoneGraphData = async () => {
    setSlaZoneLoader(true);
    setTimeout(() => {
      setSlaZoneLoader(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      complaintStatus: localStorage.getItem("stausForCategoryWise")
        ? localStorage.getItem("stausForCategoryWise")
        : "assigned",
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.misSlaZoneGraphData,
      data
    );
    if (res?.success) {
      setIsFilterReset(false);
      setSlaZoneGraphData(res?.data);
      setSlaZoneLoader(false);
    } else {
      setSlaZoneLoader(false);
    }
  };

  useEffect(() => {
    handleGetSlaZoneGraphData();
  }, [selectCategory]);

  const handleFetchData = () => {
    handleGetCategoryWiseData();
    handleGetVendorWiseGraphData();
    handleGetSlaGraphData();
    hendleGetSectorWiseData();
    handleGetSlaZoneGraphData();
    handleGetMostraisedCategory();
    handleGetMostraisedCategoryPlant();
    handleGetMostraisedProperty();
    getQuickViewComplaintsData();
  };

  const handleGetMostraisedCategory = async () => {
    setMostRaisedCategoryLoader(true);
    setTimeout(() => {
      setMostRaisedCategoryLoader(false);
    }, 50000);
    const data = {
      filters: {
        society: fetchSocietyId(),
        startDate: localStorage.getItem("fromDateforQuickView")
          ? localStorage.getItem("fromDateforQuickView")
          : startDate,
        endDate: localStorage.getItem("toDateforQuickView")
          ? localStorage.getItem("toDateforQuickView")
          : endDate,
      },
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.getMostRaisedCategory,
      data
    );
    if (res?.success) {
      setMostRaisedCategory(res?.mostRaisedCategory);
      setMostRaisedCategoryLoader(false);
    } else {
      setMostRaisedCategoryLoader(false);
    }
  };

  const handleGetMostraisedCategoryPlant = async () => {
    setMostRaisedCategoryPlantLoader(true);
    setTimeout(() => {
      setMostRaisedCategoryPlantLoader(false);
    }, 50000);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        startDate: localStorage.getItem("fromDateforQuickView")
          ? localStorage.getItem("fromDateforQuickView")
          : startDate,
        endDate: localStorage.getItem("toDateforQuickView")
          ? localStorage.getItem("toDateforQuickView")
          : endDate,
      },
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.getMostRaisedArea,
      data
    );
    if (res?.success) {
      setMostRaisedCategoryPlant(res?.data);
      setMostRaisedCategoryPlantLoader(false);
    } else {
      setMostRaisedCategoryPlantLoader(false);
    }
  };

  const handleGetMostraisedProperty = async () => {
    const data = {
      filters: {
        society: fetchSocietyId(),
        startDate: localStorage.getItem("fromDateforQuickView")
          ? localStorage.getItem("fromDateforQuickView")
          : startDate,
        endDate: localStorage.getItem("toDateforQuickView")
          ? localStorage.getItem("toDateforQuickView")
          : endDate,
      },
    };
    const res = await ApiCall(
      "post",
      AllMISDashboardRoutes.getMostRaisedProperty,
      data
    );
    if (res?.success) {
      setMostRaisedProperty(res?.mostRaisedProperty);
    }
  };

  useEffect(() => {
    handleGetMostraisedCategory();
    handleGetMostraisedCategoryPlant();
    handleGetMostraisedProperty();
    getQuickViewComplaintsData();
  }, []);

  return (
    <>
      <div
        className={classes.root}
        style={{
          filter:
            loaderCategoryWise ||
            loaderVendorWise ||
            loaderSlaData ||
            slaZoneLoader ||
            loaderSectorGraph
              ? "blur(1px)"
              : "",
          overflow:
            loaderCategoryWise ||
            loaderVendorWise ||
            loaderSlaData ||
            slaZoneLoader ||
            loaderSectorGraph
              ? "hidden"
              : "auto",
          height: "100vh",
          pointerEvents:
            loaderCategoryWise ||
            loaderVendorWise ||
            loaderSlaData ||
            slaZoneLoader ||
            loaderSectorGraph
              ? "none"
              : "",
        }}
      >
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid item lg={12} sm={12} xs={12}>
            <div class="card" style={{ borderRadius: 6, minHeight: "89vh" }}>
              <div class="card-body">
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Grid item>
                    <Grid container>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: 22,
                          fontFamily: "Poppins",
                          color: "#000000",
                        }}
                      >
                        Quick View
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid container>
                      <Typography
                        container
                        style={{
                          color: "#6c757d",
                          textAlign: "left",
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        From :&nbsp;
                        <input
                          type="date"
                          style={{
                            borderRadius: 10,
                            padding: 8,
                            border: "2px solid #E1E1E1",
                            marginBottom: 8,
                            outline: 0,
                            height: 40,
                          }}
                          onChange={(e) => {
                            localStorage.setItem(
                              "fromDateforQuickView",
                              e.target.value
                            );
                            setstartDate(e.target.value);
                          }}
                          value={
                            localStorage.getItem("fromDateforQuickView")
                              ? localStorage.getItem("fromDateforQuickView")
                              : startDate
                          }
                        />
                        &nbsp; To &nbsp;
                        <input
                          type="date"
                          style={{
                            borderRadius: 10,
                            padding: 8,
                            border: "2px solid #E1E1E1",
                            marginBottom: 8,
                            outline: 0,
                            height: 40,
                          }}
                          value={
                            localStorage.getItem("toDateforQuickView")
                              ? localStorage.getItem("toDateforQuickView")
                              : endDate
                          }
                          onChange={(e) => {
                            localStorage.setItem(
                              "toDateforQuickView",
                              e.target.value
                            );
                            setendDate(e.target.value);
                          }}
                          min={startDate}
                          max={currentDate}
                        />
                      </Typography>

                      <div
                        className="adding-button ms-2 pointer"
                        style={{
                          height: "40px",
                          paddingTop: "3px",
                          width: "130px",
                        }}
                        onClick={handleFetchData}
                      >
                        <div>
                          <p className="adding-button-text">
                            &nbsp; Fetch Data
                          </p>
                        </div>
                      </div>
                      <div
                        className="adding-button ms-2 pointer"
                        style={{
                          height: "40px",
                          paddingTop: "3px",
                          width: "100px",
                        }}
                        onClick={() => handleReset(true)}
                      >
                        <div>
                          <p className="adding-button-text">
                            <RestartAlt />
                            &nbsp; Reset
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className="scroll-container overflow-auto"
                  style={{
                    marginBottom: 10,
                    marginTop: 8,
                    gridGap: "10px",
                    display: "flex",
                    width: "80vw",
                    flexWrap: "nowrap",
                    paddingBottom: 10,
                  }}
                >
                  <div
                    className="pointer"
                    style={{
                      border:
                        selectCategory.length === 0
                          ? "1px solid #0C8CE9"
                          : "1px solid #E1E1E1",
                      padding: "12px",
                      borderRadius: "8px",
                      width: "145px",
                      flexShrink: 0,
                    }}
                    onClick={() => {
                      setSelectCategory("");
                      setCategoryName("All");
                    }}
                  >
                    <div className="d-flex gap-1">
                      {selectCategory.length === 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="#24CA26"
                          className="bi bi-check-lg mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                      )}
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          color: "#979797",
                        }}
                      >
                        All
                      </Typography>
                    </div>
                  </div>
                  {!loader &&
                    allCategory?.length > 0 &&
                    allCategory?.map((category) => (
                      <div
                        className="pointer"
                        style={{
                          border: selectCategory.includes(category?._id)
                            ? "1px solid #0C8CE9"
                            : "1px solid #E1E1E1",
                          padding: "12px",
                          borderRadius: "8px",
                          width: "155px",
                          flexShrink: 0,
                        }}
                        key={category?._id}
                        onClick={() => {
                          handleCategoryClick(category?._id);
                          setCategoryName(category?.categoryName);
                        }}
                      >
                        <div className="d-flex gap-1">
                          {selectCategory.includes(category?._id) && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="#24CA26"
                              className="bi bi-check-lg mt-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                          )}
                          <Typography
                            className="text-capitalize"
                            style={{
                              fontWeight: 400,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              color: "#979797",
                            }}
                          >
                            {category?.categoryName}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  {loader && (
                    <>
                      {[...Array(6)].map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="rectangular"
                          sx={{
                            fontSize: "1.5rem",
                            borderRadius: "8px",
                            border: "1px solid #E1E1E1",
                          }}
                          height={50}
                          width={150}
                        />
                      ))}
                    </>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  sm={12}
                  xs={12}
                  style={{ justifyContent: "space-between", marginBottom: 20 }}
                >
                  <QuickViewGraphs
                    categoryComplaintGraphData={categoryComplaintGraphData}
                    monthlyPendingComplaintGraphData={
                      monthlyPendingComplaintGraphData
                    }
                    mixedComplaintsGraphData={mixedComplaintsGraphData}
                    sectorGraphData={sectorGraphData}
                    setcategoryWiseStatus={setcategoryWiseStatus}
                    setSectorWiseStatus={setSectorWiseStatus}
                    setDownloadCsv={setDownloadCsv}
                    setCsvData={setCsvData}
                    setHeaders={setHeaders}
                    setGraphTypeName={setGraphTypeName}
                    slaZoneGraphData={slaZoneGraphData}
                    slaZoneLoader={slaZoneLoader}
                    plant={plant}
                    selectCategory={selectCategory}
                    startDate={startDate}
                    endDate={endDate}
                    vendorsId={vendorsId}
                    vendorPerformanceComplaintsData={
                      vendorPerformanceComplaintsData
                    }
                  />
                  <PerformanceCounts
                    vendorPerformanceData={vendorPerformanceData}
                    vendorPerformanceDataLoader={vendorPerformanceDataLoader}
                    complaintCounts={complaintCounts}
                    mostRaisedCategory={mostRaisedCategory}
                    mostRaisedCategoryPlant={mostRaisedCategoryPlant}
                    mostRaisedProperty={mostRaisedProperty}
                    categoryName={categoryName}
                    plant={plant}
                    setCsvData={setCsvData}
                    setDownloadCsv={setDownloadCsv}
                    setHeaders={setHeaders}
                    setGraphTypeName={setGraphTypeName}
                    selectCategory={selectCategory}
                    startDate={startDate}
                    endDate={endDate}
                    vendorPerformanceComplaintsData={
                      vendorPerformanceComplaintsData
                    }
                    mostRaisedCategoryLoader={mostRaisedCategoryLoader}
                    mostRaisedCategoryPlantLoader={
                      mostRaisedCategoryPlantLoader
                    }
                  />
                </Grid>
              </div>
            </div>
          </Grid>
        </main>
      </div>

      <Modal open={downloadCsv}>
        <Paper
          style={{ backgroundColor: "#fff" }}
          className={classes.deleteModal}
        >
          {csvData?.length > 0 && (
            <>
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 10 }}
              >
                <div className="h-440p d-flex flex-column align-items-center justify-content-center">
                  <svg
                    width="90"
                    height="90"
                    viewBox="0 0 136 136"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M76.1295 4.82006C71.9283 -0.311124 64.0815 -0.311106 59.8809 4.82006L53.9297 12.0888C53.5218 12.5869 52.8414 12.7693 52.2391 12.5418L43.451 9.22238C37.2473 6.8792 30.4517 10.8025 29.3791 17.3468L27.8597 26.6172C27.7556 27.2526 27.2575 27.7507 26.6221 27.8548L17.3517 29.3742C10.8075 30.4468 6.88403 37.2423 9.22727 43.4461L12.5466 52.2342C12.7742 52.8366 12.5918 53.517 12.0936 53.9248L4.82495 59.8758C-0.306239 64.077 -0.306227 71.9238 4.82495 76.125L12.0936 82.0758C12.5918 82.4837 12.7742 83.1642 12.5466 83.7666L9.22727 92.5547C6.88409 98.7581 10.8074 105.554 17.3517 106.627L26.6221 108.146C27.2575 108.25 27.7556 108.748 27.8597 109.384L29.3791 118.654C30.4517 125.198 37.2472 129.121 43.451 126.778L52.2391 123.459C52.8414 123.232 53.5218 123.413 53.9297 123.912L59.8809 131.18C64.0815 136.312 71.9283 136.312 76.1295 131.18L82.0804 123.912C82.4883 123.413 83.1687 123.232 83.7711 123.459L92.5593 126.778C98.7633 129.121 105.559 125.198 106.631 118.654L108.151 109.384C108.255 108.748 108.753 108.25 109.388 108.146L118.659 106.627C125.203 105.554 129.126 98.7581 126.783 92.5547L123.464 83.7666C123.236 83.1642 123.419 82.4837 123.917 82.0758L131.186 76.125C136.317 71.9238 136.317 64.077 131.186 59.8758L123.917 53.9248C123.419 53.517 123.236 52.8366 123.464 52.2342L126.783 43.4461C129.126 37.2423 125.203 30.4468 118.659 29.3742L109.388 27.8548C108.753 27.7507 108.255 27.2526 108.151 26.6172L106.631 17.3468C105.559 10.8026 98.7633 6.87914 92.5593 9.22238L83.7711 12.5418C83.1687 12.7693 82.4883 12.5869 82.0804 12.0888L76.1295 4.82006Z"
                      fill="#4DC4FF"
                    />
                  </svg>
                  <svg
                    width="56"
                    height="32"
                    viewBox="0 0 76 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginTop: "-70%" }}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M74.1818 1.818C75.9392 3.57534 75.9392 6.42462 74.1818 8.18196L32.1818 50.1818C31.3382 51.026 30.1934 51.5 29 51.5C27.8066 51.5 26.6618 51.026 25.818 50.1818L1.818 26.1818C0.0606651 24.4244 0.0606651 21.5756 1.818 19.8182C3.57534 18.0608 6.42463 18.0608 8.18197 19.8182L29 40.6358L67.8182 1.818C69.5756 0.0606651 72.4244 0.0606651 74.1818 1.818Z"
                      fill="#ECEFF1"
                    />
                  </svg>
                </div>
              </Grid>
              <Typography
                style={{
                  fontSize: 23,
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 30,
                }}
              >
                Success !
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 6,
                }}
              >
                Data is successfully fetched and ready for download
              </Typography>
              <Grid
                container
                style={{
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 15,
                }}
              >
                <div
                  className="adding-button pointer"
                  onClick={() => {
                    setDownloadCsv(false);
                  }}
                  style={{ border: "1px solid #EE383D", width: "120px" }}
                >
                  <div>
                    <p
                      className="adding-button-text"
                      style={{ color: "#EE383D" }}
                    >
                      <Close style={{ marginRight: 6, fontSize: 16 }} /> Cancel
                    </p>
                  </div>
                </div>
                <ExportJsonCsv
                  fileTitle={
                    authState.user?.name +
                    `(${authState.user?.email})` +
                    "-" +
                    `${graphTypeName}`
                  }
                  headers={headers}
                  items={csvData}
                  onClick={() => {
                    setDownloadCsv(false);
                    csvData([]);
                  }}
                  className="adding-button pointer"
                  style={{
                    marginLeft: 24,
                    width: "200px",
                    backgroundColor: "transparent",
                    border: "1px solid #04D000",
                  }}
                >
                  <p
                    className="adding-button-text"
                    style={{ color: "#04D000" }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: 6 }}
                    >
                      <path
                        d="M4.66699 19.8334V22.1667C4.66699 22.7856 4.91282 23.3791 5.35041 23.8167C5.78799 24.2542 6.38149 24.5001 7.00033 24.5001H21.0003C21.6192 24.5001 22.2127 24.2542 22.6502 23.8167C23.0878 23.3791 23.3337 22.7856 23.3337 22.1667V19.8334M8.16699 12.8334L14.0003 18.6667M14.0003 18.6667L19.8337 12.8334M14.0003 18.6667V4.66675"
                        stroke="#04D000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Download Report
                  </p>
                </ExportJsonCsv>
              </Grid>
            </>
          )}
          {csvData?.length === 0 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 6,
                }}
              >
                No data for Download
              </Typography>
              <Grid
                container
                style={{
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 15,
                }}
              >
                <div
                  className="adding-button pointer"
                  onClick={() => {
                    setDownloadCsv(false);
                  }}
                  style={{ border: "1px solid #EE383D", width: "140px" }}
                >
                  <div>
                    <p
                      className="adding-button-text"
                      style={{ color: "#EE383D" }}
                    >
                      <Close style={{ marginRight: 6, fontSize: 16 }} /> Cancel
                    </p>
                  </div>
                </div>
              </Grid>
            </>
          )}
        </Paper>
      </Modal>

      {(loaderCategoryWise ||
        loaderVendorWise ||
        loaderSlaData ||
        slaZoneLoader ||
        loaderSectorGraph) && <BanterLoader />}
    </>
  );
};

export default QuickViewNew;
