import React, { useState, useContext, useEffect } from "react";
import { Typography, Avatar, Badge } from "@material-ui/core";
import Clock from "react-live-clock";
import { AuthContext } from "../../contextAPI/context";
import { BASE_URL } from "../../utils/base";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import "./navbar.css";
import {
  ArrowDropDown,
  ArrowDropUp,
  CheckCircleOutline,
} from "@material-ui/icons";
import { fetchSocietyDetails } from "../../contextAPI/fetchSociety";
import Dropper from "./Dropper";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Navbar = (props) => {
  const { authState, authDispatch } = useContext(AuthContext);
  const [currentdate, setCurrentDate] = useState(new Date());
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
  const { user, memberType, usermoduleswithpermissions } = authState;
  const [isActive, setIsActive] = useState(false);
  const [societyName, setsocietyName] = useState("");
  const { pathName } = props;
  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  useEffect(async () => {
    setsocietyName(await fetchSocietyDetails("name"));
  }, [authState]);

  useEffect(() => {
    const timerrr = setInterval(() => {
      setCurrentDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timerrr);
    };
  }, []);

  useEffect(() => {
    setIsActive(false);
  }, [pathName]);

  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat, sans-serif",
          color: "#000",
          fontWeight: "bold",
        }}
      >
        <CheckCircleOutline style={{ color: "rgb(36 202 38)" }} />
        &nbsp;
        {societyName ? societyName : "Loading..."}
      </Typography>
      <div style={{ flexGrow: 1 }} />

      <Clock
        date={currentdate.toString()}
        format={"h:mm A"}
        timezone={"Asia/Kolkata"}
        style={{ fontFamily: "Poppins, sans-serif", fontSize: "20px" }}
      />

      <div style={{ flexGrow: 1 }} />

      <Stack direction="row" spacing={2} onClick={toggleDropdown}>
        <div>
          <Button>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                src={user?.image && `${BASE_URL}/user/${user.image}`}
                style={{ border: "1px solid #c8c8c8" }}
              />
            </StyledBadge>
            <Typography
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                color: "#000",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
              }}
            >
              <span>&nbsp;&nbsp;{user?.name} </span>
              <br />
            </Typography>
            {isActive ? (
              <ArrowDropUp style={{ cursor: "pointer", color: "#1e2082" }} />
            ) : (
              <ArrowDropDown style={{ cursor: "pointer", color: "#1e2082" }} />
            )}
          </Button>
          {isActive && (
            <Dropper
              user={user}
              refreshToken={refreshToken}
              authDispatch={authDispatch}
              setIsActive={setIsActive}
              memberType={memberType}
              usermoduleswithpermissions={usermoduleswithpermissions}
            />
          )}
        </div>
      </Stack>
    </>
  );
};

export default Navbar;
