import { useRoutes } from "react-router-dom";
import DiscussionRoutes from "./Communication/DiscussionRoutes";
import MeetingRoutes from "./Communication/MeetingRoutes";
import NoticeRoutes from "./Communication/NoticeRoutes";
import PollRoutes from "./Communication/PollRoutes";
import HomeRoutes from "./Other/HomeRoutes";
import ErrorRoute from "./Other/ErrorRoutes";
import { useContext } from "react";
import { AuthContext } from "../contextAPI/context";
import VisitorRoutes from "./Gate Management/Visitor";
import StaffRoutes from "./Gate Management/Staff";
import SettingRoutes from "./Gate Management/GateManagementSettings";
import ResidentRoutes from "./Gate Management/Resident";
import DailyHelpRoutes from "./Help/Daily Help";
import EmergencyRoutes from "./Help/Emergency";
import LocalDirectoryRoutes from "./Help/Local Directory";
import AdminRoutes from "./People/Admin";
import MemberRoutes from "./People/Member";
import PeopleResidentRoutes from "./People/Resident";
import VendorRoutes from "./People/Vendor";
import MisDashboardRoutes from "./Mis Dashboard/MisDashboard";
import QuickviewRoutes from "./Mis Dashboard/Quickview";
import SocietyTypeRoutes from "./Settings/SocietyType";
import SocietyManagementRoutes from "./Settings/SocietyManagement";
import CategoryRoutes from "./Complaint/CategoryRoutes";
import HelpdeskRoutes from "./Complaint/HelpdeskRoutes";
import SocietyRequestsRoutes from "./Settings/SocietyRequests";
import { isMemberTypeS2Admin } from "../GlobalFunctions/GlobalFunctions";
import ConfigurationRoutes from "./S2Admin/Configuration";
import AdminSocietyRequests from "./S2Admin/AdminSocietyRequests";
import AdminApprovalRoutes from "./S2Admin/AdminApproval";
import AdvanceVendorRoutes from "./Complaint/AdvanceVendorRoutes";
import ConfigurationSettingRoutes from "./Settings/ConfigurationSetting";
import ApprovalRoutes from "./Complaint/ApprovalRoutes";
import GroupRoutes from "./People/Group";
import ManagerRoutes from "./People/Manager";
import AreaRoutes from "./Complaint/AreaRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { authState } = useContext(AuthContext);
  const { permissionmodules, user } = authState;
  const {
    REACT_APP_HELPDESK_MODULE,
    REACT_APP_CATEGORY_MODULE,
    REACT_APP_APPROVAL_MODULE,
    REACT_APP_NOTICE_MODULE,
    REACT_APP_DISCUSSION_MODULE,
    REACT_APP_POLL_MODULE,
    REACT_APP_MEETING_MODULE,
    REACT_APP_VISITOR_MODULE,
    REACT_APP_STAFF_MODULE,
    REACT_APP_RESIDENT_MODULE,
    REACT_APP_GATEMANAGEMENTSETTINGS_MODULE,
    REACT_APP_MISDASHBOARD_MODULE,
    REACT_APP_QUICKVIEW_MODULE,
    REACT_APP_ADMIN_MODULE,
    REACT_APP_MEMBER_MODULE,
    REACT_APP_PEOPLERESIDENT_MODULE,
    REACT_APP_VENDOR_MODULE,
    REACT_APP_TYPE_MODULE,
    REACT_APP_SOCIETYMANAGE_MODULE,
    REACT_APP_DAILYHELP_MODULE,
    REACT_APP_EMERGENCY_MODULE,
    REACT_APP_LOCALDIRECTORY_MODULE,
    REACT_APP_GROUP_MODULE,
    REACT_APP_MANAGER_MODULE,
    REACT_APP_AREA_MODULE
  } = process.env;

  const checkPrivateModules = (moduleName) => {
    let isPublic = permissionmodules?.includes(moduleName);
    return isPublic;
  };
  return useRoutes([
    HomeRoutes,
    ErrorRoute,
    checkPrivateModules(REACT_APP_HELPDESK_MODULE) && HelpdeskRoutes,
    checkPrivateModules(REACT_APP_CATEGORY_MODULE) && CategoryRoutes,
    checkPrivateModules(REACT_APP_APPROVAL_MODULE) && ApprovalRoutes,
    checkPrivateModules(REACT_APP_AREA_MODULE) && AreaRoutes,
    checkPrivateModules(REACT_APP_MANAGER_MODULE) && ManagerRoutes,
    AdvanceVendorRoutes,
    checkPrivateModules(REACT_APP_NOTICE_MODULE) && NoticeRoutes,
    checkPrivateModules(REACT_APP_MEETING_MODULE) && MeetingRoutes,
    checkPrivateModules(REACT_APP_POLL_MODULE) && PollRoutes,
    checkPrivateModules(REACT_APP_DISCUSSION_MODULE) && DiscussionRoutes,
    checkPrivateModules(REACT_APP_VISITOR_MODULE) && VisitorRoutes,
    checkPrivateModules(REACT_APP_STAFF_MODULE) && StaffRoutes,
    checkPrivateModules(REACT_APP_RESIDENT_MODULE) && ResidentRoutes,
    checkPrivateModules(REACT_APP_GATEMANAGEMENTSETTINGS_MODULE) &&
      SettingRoutes,
    // checkPrivateModules(REACT_APP_MISDASHBOARD_MODULE) && MisDashboardRoutes,
    checkPrivateModules(REACT_APP_QUICKVIEW_MODULE) && QuickviewRoutes,
    checkPrivateModules(REACT_APP_ADMIN_MODULE) && AdminRoutes,
    checkPrivateModules(REACT_APP_MEMBER_MODULE) && MemberRoutes,
    checkPrivateModules(REACT_APP_GROUP_MODULE) && GroupRoutes,
    checkPrivateModules(REACT_APP_PEOPLERESIDENT_MODULE) &&
      PeopleResidentRoutes,
    checkPrivateModules(REACT_APP_VENDOR_MODULE) && VendorRoutes,
    // checkPrivateModules(REACT_APP_TYPE_MODULE) &&
    SocietyTypeRoutes,
    // checkPrivateModules(REACT_APP_SOCIETYMANAGE_MODULE) &&
    SocietyManagementRoutes,
    ConfigurationSettingRoutes,
    checkPrivateModules(REACT_APP_DAILYHELP_MODULE) && DailyHelpRoutes,
    checkPrivateModules(REACT_APP_EMERGENCY_MODULE) && EmergencyRoutes,
    checkPrivateModules(REACT_APP_LOCALDIRECTORY_MODULE) &&
      LocalDirectoryRoutes,
    SocietyRequestsRoutes,
    // ********** S2 ADMIN ROUTES *************
    isMemberTypeS2Admin(user?.role) &&
      checkPrivateModules("Configuration") &&
      ConfigurationRoutes,
    isMemberTypeS2Admin(user?.role) &&
      checkPrivateModules("Society Request") &&
      AdminSocietyRequests,
    isMemberTypeS2Admin(user?.role) &&
      checkPrivateModules("Admin Approval") &&
      AdminApprovalRoutes,
  ]);
}
