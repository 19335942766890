import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CategoryComplaintGraphQV from "./CategoryComplaintGraphQV";
import MonthlyPendingComplaintGraphQV from "./MonthlyPendingComplaintGraphQV";
import MixedComplaintsGraphQV from "./MixedComplaintGraphQV";
import SectorComplaintGraphQV from "./SectorComplaintGraphQV";
import SlaZoneGraph from "./SlaZoneGraph";
import Loader from "../../../GlobalComponents/Loaders/Loader";
import { AllMISCSVRoutes } from "../../../api/Routes";
import ApiCall from "../../../api/ApiCall";
import moment from "moment";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import CsvApi from "../../../api/CsvApi";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";

const QuickViewGraphs = ({
  categoryComplaintGraphData,
  monthlyPendingComplaintGraphData,
  mixedComplaintsGraphData,
  sectorGraphData,
  setcategoryWiseStatus,
  setSectorWiseStatus,
  setDownloadCsv,
  setCsvData,
  setHeaders,
  setGraphTypeName,
  slaZoneGraphData,
  plant,
  selectCategory,
  startDate,
  endDate,
  vendorsId,
  vendorPerformanceComplaintsData,
}) => {
  const [downloadVendorSlaDropdown, setDownloadVendorSlaDropdown] =
    useState(false);
  const [
    downloadCategoryWiseComplaintDropdown,
    setDownloadCategoryWiseComplaintDropdown,
  ] = useState(false);
  const [
    downloadBeyondSlaComplaintsDropdown,
    setDownloadBeyondSlaComplaintsDropdown,
  ] = useState(false);
  const [downloadZoneSlaDropdown, setDownloadZoneSlaDropdown] = useState(false);
  const [
    downloadSectorWiseComplaintsDropdown,
    setDownloadSectorWiseComplaintsDropdown,
  ] = useState(false);

  const [categoryWiseComplaintDataLoader, setcategoryWiseComplaintDataLoader] =
    useState(false);
  const [
    categoryWiseComplaintDataLoader2,
    setcategoryWiseComplaintDataLoader2,
  ] = useState(false);
  const [
    categoryWiseComplaintDataLoader3,
    setcategoryWiseComplaintDataLoader3,
  ] = useState(false);
  const [
    categoryWiseComplaintDataLoader4,
    setcategoryWiseComplaintDataLoader4,
  ] = useState(false);
  const [
    categoryWiseComplaintDataLoader5,
    setcategoryWiseComplaintDataLoader5,
  ] = useState(false);

  const downloadCsvCategoryWise = () => {
    const temp = [];
    categoryComplaintGraphData.forEach((data) =>
      temp.push({
        category: data.categoryName,
        numberOfComplaints: data.numberOfComplaints,
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "category",
        name: "Category",
      },
      {
        key: "numberOfComplaints",
        name: "Number Of Complaints",
      },
    ]);
    setGraphTypeName("Category Wise Complaint");
  };

  const downloadCsvVendorWise = () => {
    const temp = [];
    monthlyPendingComplaintGraphData?.forEach((data) =>
      temp.push({
        vendor: data.vendorName,
        total: data.totalComplaints,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
        withinSLAPercentage:
          data.totalComplaints > 0
            ? `${((data.inTime / data.totalComplaints) * 100).toFixed(2)}%`
            : "0.00%",
        beyondSLAPercentage:
          data.totalComplaints > 0
            ? `${((data.outOfTime / data.totalComplaints) * 100).toFixed(2)}%`
            : "0.00%",
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "vendor",
        name: "Vendor",
      },
      {
        key: "total",
        name: "Total Complaints",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "withinSLAPercentage",
        name: "Within SLA Percentage",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
      {
        key: "beyondSLAPercentage",
        name: "Beyond SLA Percentage",
      },
    ]);
    setGraphTypeName("Vendor Wise Complaint");
  };

  const downloadCsvSLaWise = () => {
    const temp = [];
    mixedComplaintsGraphData.forEach((data) =>
      temp.push({
        sector: data.sectorName,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "sector",
        name: "Sector",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
    ]);
    setGraphTypeName("Sla/ Beyond Sla Complaint");
  };

  const downloadCsvSectorWise = () => {
    const temp = [];
    sectorGraphData.forEach((data) =>
      temp.push({
        sector: data.sectorName,
        numberOfComplaints: data.numberOfComplaints,
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "sector",
        name: "Sector",
      },
      {
        key: "numberOfComplaints",
        name: "Number Of Complaints",
      },
    ]);
    setGraphTypeName("Sector Wise Complaint");
  };

  const downloadCsvSlaZoneWise = () => {
    const temp = [];
    slaZoneGraphData.forEach((data) =>
      temp.push({
        zone: data.zoneName,
        withinSLA: data.inTime ? data.inTime : "-",
        beyondSLA: data.outOfTime ? data.outOfTime : "-",
      })
    );

    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "zone",
        name: "Zone",
      },
      {
        key: "withinSLA",
        name: "Within SLA",
      },
      {
        key: "beyondSLA",
        name: "Beyond SLA",
      },
    ]);
    setGraphTypeName("Sla/ Beyond Sla Zone Data");
  };

  // Handle Download Category Wise All Complaints
  const handleDownloadCategoryWiseAllComplaints = async () => {
    setcategoryWiseComplaintDataLoader(true);
    setTimeout(() => {
      setcategoryWiseComplaintDataLoader(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      complaintStatus: localStorage.getItem("stausForCategoryWise")
        ? localStorage.getItem("stausForCategoryWise")
        : "assigned",
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      // vendors: vendorsId,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getCategoryWiseComplainData,
      data
    );
    if (res?.success) {
      toast.success("CSV downloaded successfully!");
      setcategoryWiseComplaintDataLoader(false);
    } else {
      setcategoryWiseComplaintDataLoader(false);
    }
  };

  // Handle Download SLA Complaint Data
  const handleDownloadSlaAllComplaintData = async () => {
    setcategoryWiseComplaintDataLoader2(true);
    setTimeout(() => {
      setcategoryWiseComplaintDataLoader2(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      // category: selectCategory,
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      // vendors: vendorsId,
    };
    const res = await CsvApi("post", AllMISCSVRoutes?.getSlaComplainData, data);
    if (res?.success) {
      toast.success("CSV downloaded successfully!");
      setcategoryWiseComplaintDataLoader2(false);
    } else {
      setcategoryWiseComplaintDataLoader2(false);
    }
  };

  // Handle Download Zone Wise Complaint Data
  const handleDownloadZoneWiseComplaintData = async () => {
    setcategoryWiseComplaintDataLoader3(true);
    setTimeout(() => {
      setcategoryWiseComplaintDataLoader3(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      complaintStatus: localStorage.getItem("stausForCategoryWise")
        ? localStorage.getItem("stausForCategoryWise")
        : "assigned",
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      // vendors: vendorsId,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getZoneWiseComplainData,
      data
    );
    if (res?.success) {
      toast.success("CSV downloaded successfully!");
      setcategoryWiseComplaintDataLoader3(false);
    } else {
      setcategoryWiseComplaintDataLoader3(false);
    }
  };

  // Handle Download Sector Wise SLA All Complaints
  const handleDownloadSectorWiseSlaAllComplaints = async () => {
    setcategoryWiseComplaintDataLoader4(true);
    setTimeout(() => {
      setcategoryWiseComplaintDataLoader4(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      complaintStatus: localStorage.getItem("stausForSectorWise")
        ? localStorage.getItem("stausForSectorWise")
        : "assigned",
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      // vendors: vendorsId,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getSectorWiseComplainData,
      data
    );
    if (res?.success) {
      toast.success("CSV downloaded successfully!");
      setcategoryWiseComplaintDataLoader4(false);
    } else {
      setcategoryWiseComplaintDataLoader4(false);
    }
  };

  // Handle Download Sector Wise SLA All Complaints
  const handleDownloadVendorWiseSlaAllComplaints = async () => {
    setcategoryWiseComplaintDataLoader5(true);
    setTimeout(() => {
      setcategoryWiseComplaintDataLoader5(false);
    }, 50000);
    const data = {
      societyId: fetchSocietyId(),
      complaintStatus: localStorage.getItem("stausForCategoryWise")
        ? localStorage.getItem("stausForCategoryWise")
        : "assigned",
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
      vendors: vendorsId,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getVendorWiseComplainData,
      data
    );
    if (res?.success) {
      toast.success("CSV downloaded successfully!");
      setcategoryWiseComplaintDataLoader5(false);
    } else {
      setcategoryWiseComplaintDataLoader5(false);
    }
  };

  return (
    <>
      <Grid
        container
        item
        spacing={2}
        xl={9}
        lg={9}
        md={8}
        sm={12}
        xs={12}
        style={{ marginBottom: 10 }}
      >
        {/* CATEGORY WISE COMPLAINT */}
        <Grid item xs={12}>
          <div className="d-flex justify-content-between">
            <Typography
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                color: "#979797",
                fontSize: "16px",
              }}
            >
              Complaints
            </Typography>
            <div class="dropdown">
              <button
                class="btn"
                onClick={() =>
                  setDownloadCategoryWiseComplaintDropdown(
                    !downloadCategoryWiseComplaintDropdown
                  )
                }
              >
                {categoryWiseComplaintDataLoader ? (
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: "#2f45c5",
                      marginLeft: 15,
                    }}
                  />
                ) : (
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                      stroke="#0C8CE9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
              {downloadCategoryWiseComplaintDropdown && (
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdown_Vendor_SLA_Button"
                >
                  <li>
                    <span
                      class="dropdown-item pointer"
                      onClick={() => {
                        downloadCsvCategoryWise();
                        setDownloadCategoryWiseComplaintDropdown(false);
                      }}
                    >
                      Download Count Report
                    </span>
                  </li>
                  <li>
                    <span
                      class="dropdown-item pointer"
                      onClick={() => {
                        handleDownloadCategoryWiseAllComplaints();
                        setDownloadCategoryWiseComplaintDropdown(false);
                      }}
                    >
                      Download Details Report
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 400,
              marginTop: 8,
              padding: "6px 14px",
            }}
          >
            <div className="d-flex justify-content-end">
              <select
                className="form-select form-select-md pointer"
                style={{
                  color: "#0C8CE9",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  height: 40,
                  fontSize: "16px",
                  padding: 8,
                  paddingLeft: 8,
                  border: "1px solid #0C8CE9",
                  textAlign: "start",
                  width: "120px",
                }}
                onChange={(event) => {
                  localStorage.setItem(
                    "stausForCategoryWise",
                    event.target.value
                  );
                  setcategoryWiseStatus(event.target.value);
                }}
                value={localStorage.getItem("stausForCategoryWise")}
              >
                <option value="all">All</option>
                <option value="assigned">Assigned</option>
                <option value="resolved">Resolved</option>
                <option value="closed">Closed</option>
                <option value="cancel">Cancelled</option>
              </select>
            </div>
            <CategoryComplaintGraphQV
              data={categoryComplaintGraphData}
              vendorPerformanceComplaintsData={vendorPerformanceComplaintsData}
            />
          </div>
        </Grid>
        {/* Vendor wise complaints */}
        <Grid item xs={12}>
          <div className="d-flex justify-content-between">
            <Typography
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                color: "#979797",
                fontSize: "16px",
              }}
            >
              Vendor SLA
            </Typography>

            <div class="dropdown">
              <button
                class="btn"
                onClick={() =>
                  setDownloadVendorSlaDropdown(!downloadVendorSlaDropdown)
                }
              >
                {categoryWiseComplaintDataLoader5 ? (
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: "#2f45c5",
                      marginLeft: 15,
                    }}
                  />
                ) : (
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                      stroke="#0C8CE9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
              {downloadVendorSlaDropdown && (
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdown_Vendor_SLA_Button"
                >
                  <li>
                    <span
                      class="dropdown-item pointer"
                      onClick={() => {
                        downloadCsvVendorWise();
                        setDownloadVendorSlaDropdown(false);
                      }}
                    >
                      Download Count Report
                    </span>
                  </li>
                  <li>
                    <span
                      class="dropdown-item pointer"
                      onClick={() => {
                        handleDownloadVendorWiseSlaAllComplaints();
                        setDownloadVendorSlaDropdown(false);
                      }}
                    >
                      Download Details Report
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 400,
              marginTop: 8,
              padding: "12px 6px",
            }}
          >
            <MonthlyPendingComplaintGraphQV
              data={monthlyPendingComplaintGraphData}
            />
          </div>
        </Grid>

        {/* SLA / Beyond SLA complaints */}
        {!plant && (
          <Grid item lg={plant ? 12 : 6} md={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              <Typography
                style={{
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  color: "#979797",
                  fontSize: "16px",
                }}
              >
                Sector SLA
              </Typography>

              <div class="dropdown">
                <button
                  class="btn"
                  onClick={() =>
                    setDownloadBeyondSlaComplaintsDropdown(
                      !downloadBeyondSlaComplaintsDropdown
                    )
                  }
                >
                  {categoryWiseComplaintDataLoader2 ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : (
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                        stroke="#0C8CE9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {downloadBeyondSlaComplaintsDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          downloadCsvSLaWise();
                          setDownloadBeyondSlaComplaintsDropdown(false);
                        }}
                      >
                        Download Count Report
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          handleDownloadSlaAllComplaintData();
                          setDownloadBeyondSlaComplaintsDropdown(false);
                        }}
                      >
                        Download Details Report
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div
              style={{
                border: "1px solid #E1E1E1",
                borderRadius: "10px",
                height: 400,
                marginTop: 8,
                padding: "12px 6px",
              }}
            >
              <MixedComplaintsGraphQV data={mixedComplaintsGraphData} />
            </div>
          </Grid>
        )}
        {/* ZONE SLA */}
        {plant && (
          <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              <Typography
                style={{
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  color: "#979797",
                  fontSize: "16px",
                }}
              >
                Zone SLA
              </Typography>

              <div class="dropdown">
                <button
                  class="btn"
                  onClick={() =>
                    setDownloadZoneSlaDropdown(!downloadZoneSlaDropdown)
                  }
                >
                  {categoryWiseComplaintDataLoader3 ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : (
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                        stroke="#0C8CE9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {downloadZoneSlaDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          downloadCsvSlaZoneWise();
                          setDownloadZoneSlaDropdown(false);
                        }}
                      >
                        Download Count Report
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          handleDownloadZoneWiseComplaintData();
                          setDownloadZoneSlaDropdown(false);
                        }}
                      >
                        Download Details Report
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div
              style={{
                border: "1px solid #E1E1E1",
                borderRadius: "10px",
                height: 400,
                marginTop: 8,
                padding: "12px 6px",
              }}
            >
              <SlaZoneGraph data={slaZoneGraphData} />
            </div>
          </Grid>
        )}
        {/* SECTOR WISE COMPLAINTS*/}
        {!plant && (
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className="d-flex justify-content-between">
              <Typography
                style={{
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  color: "#979797",
                  fontSize: "16px",
                }}
              >
                Sector Complaints
              </Typography>

              <div class="dropdown">
                <button
                  class="btn"
                  onClick={() =>
                    setDownloadSectorWiseComplaintsDropdown(
                      !downloadSectorWiseComplaintsDropdown
                    )
                  }
                >
                  {categoryWiseComplaintDataLoader4 ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : (
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                        stroke="#0C8CE9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {downloadSectorWiseComplaintsDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          downloadCsvSectorWise();
                          setDownloadSectorWiseComplaintsDropdown(false);
                        }}
                      >
                        Download Count Report
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          handleDownloadSectorWiseSlaAllComplaints();
                          setDownloadSectorWiseComplaintsDropdown(false);
                        }}
                      >
                        Download Details Report
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div
              style={{
                border: "1px solid #E1E1E1",
                borderRadius: "10px",
                height: 400,
                marginTop: 8,
                padding: "6px 14px",
              }}
            >
              <div className="d-flex justify-content-end">
                <select
                  className="form-select form-select-md pointer"
                  style={{
                    color: "#0C8CE9",
                    backgroundColor: "#fff",
                    borderRadius: 8,
                    height: 40,
                    fontSize: "16px",
                    padding: 8,
                    paddingLeft: 8,
                    border: "1px solid #0C8CE9",
                    textAlign: "start",
                    width: "120px",
                  }}
                  onChange={(event) => {
                    localStorage.setItem(
                      "stausForSectorWise",
                      event.target.value
                    );
                    setSectorWiseStatus(event.target.value);
                  }}
                  value={localStorage.getItem("stausForSectorWise")}
                >
                  <option value="all">All</option>
                  <option value="assigned">Assigned</option>
                  <option value="resolved">Resolved</option>
                  <option value="closed">Closed</option>
                  <option value="cancel">Cancelled</option>
                </select>
              </div>
              <SectorComplaintGraphQV data={sectorGraphData} />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default QuickViewGraphs;
