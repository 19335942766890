import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

export default function CategoryComplaintGraphQV({
  data,
  vendorPerformanceComplaintsData,
}) {
  const totalComplaints = vendorPerformanceComplaintsData?.[0]?.totalComplaints;

  return (
    <ResponsiveContainer width="100%" height={330}>
      <BarChart
        data={data}
        margin={{
          top: 30,
          right: 5,
          bottom: 5,
          left: 5,
        }}
        id="category-data"
      >
        {/* X-Axis */}
        <XAxis
          type="category"
          dataKey="categoryName"
          axisLine={false}
          interval={0}
          tick={{
            fontSize: 8,
            fontWeight: "bold",
            fill: "#000",
            width: 60,
            whiteSpace: "pre-wrap",
            overflow: "hidden",
          }}
          tickFormatter={(categoryName) => categoryName}
        />

        {/* Y-Axis */}
        <YAxis hide axisLine={false} type="number" />

        <Tooltip />
        <Legend />

        <Bar
          dataKey="numberOfComplaints"
          fill="#0C8CE9"
          name="Category-wise Complaints"
          barSize={70}
          radius={[10, 10, 0, 0]}
        >
          <LabelList
            dataKey="numberOfComplaints"
            position="top"
            formatter={(value) => {
              const percentage = totalComplaints
                ? ((value / totalComplaints) * 100).toFixed(1)
                : 0;
              return `${percentage}%`;
            }}
            fill="#000"
            style={{
              fontSize: 10,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
            }}
          />

          <LabelList
            dataKey="numberOfComplaints"
            position="center"
            fill="#fff"
            style={{
              fontSize: 9,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
