import { Avatar, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import { AllMISCSVRoutes } from "../../../api/Routes";
import CsvApi from "../../../api/CsvApi";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";

const PerformanceCounts = ({
  vendorPerformanceData,
  vendorPerformanceDataLoader,
  complaintCounts,
  mostRaisedCategory,
  mostRaisedCategoryPlant,
  mostRaisedProperty,
  categoryName,
  plant,
  setCsvData,
  setDownloadCsv,
  setHeaders,
  setGraphTypeName,
  selectCategory,
  startDate,
  endDate,
  vendorPerformanceComplaintsData,
  mostRaisedCategoryLoader,
  mostRaisedCategoryPlantLoader,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [areaLoader, setAreaLoader] = useState(false);
  const [vendorComplaintLoader, setVendorComplaintLoader] = useState(false);
  const [categoriesIds, setCategoriesIds] = useState([]);
  const [areaIds, setAreaIds] = useState([]);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [performingVendorDropdown, setPerformingVendorDropdown] =
    useState(false);
  const [
    performingVendorComplaintsDropdown,
    setPerformingVendorComplaintsDropdown,
  ] = useState(false);

  const [performingVendorToggle, setPerformingVendorToggle] = useState(
    "Highest Performing Vendor"
  );
  const [activePerformingVendor, setActivePerformingVendor] = useState(null);
  const [societyWiseComplaintsDataLoader, setsocietyWiseComplaintsDataLoader] =
    useState(false);
  const [
    propertyWiseComplaintsDataLoader,
    setPropertyWiseComplaintsDataLoader,
  ] = useState(false);
  const [
    societyWiseComplaintsDataDropdown,
    setSocietyWiseComplaintsDataDropdown,
  ] = useState(false);
  const [mostRaisedCategoryDropdown, setMostRaisedCategoryDropdown] =
    useState(false);
  const [mostRaisedCategoryPlantDropdown, setMostRaisedCategoryPlantDropdown] =
    useState(false);
  const [mostRaisedPropertyDropdown, setMostRaisedPropertyDropdown] =
    useState(false);
  const [isArea, setIsArea] = useState(false);

  useEffect(() => {
    if (vendorPerformanceData) {
      setActivePerformingVendor(vendorPerformanceData?.highestRatedVendor);
    }
  }, [vendorPerformanceData]);

  const handlesetCategoriesIds = () => {
    const ids = mostRaisedCategory?.flatMap((category) => category?._id) || [];
    setCategoriesIds(ids);
    setIsSuccessModal(true);
  };

  const handlesetCategoriesPlantIds = () => {
    setIsArea(true);
    const ids =
      mostRaisedCategoryPlant?.flatMap((category) => category?._id) || [];
    setAreaIds(ids);
    setIsSuccessModal(true);
  };

  const handleDownloadCategoryComplaintData = async () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
    const data = {
      society: fetchSocietyId(),
      categories: categoriesIds,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getCategoryComplainData,
      data
    );
    if (res?.success) {
      toast.success("Highest performing vendors data downloaded successfully!");
      setLoader(false);
      setIsSuccessModal(false);
    } else {
      setLoader(false);
    }
  };

  const handleDownloadAreaComplaintData = async () => {
    setIsArea(false);
    setAreaLoader(true);
    setTimeout(() => {
      setAreaLoader(false);
    }, 50000);
    const data = {
      society: fetchSocietyId(),
      areas: areaIds,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await CsvApi("post", AllMISCSVRoutes?.getAreaWise, data);
    if (res?.success) {
      toast.success("Highest performing vendors data downloaded successfully!");
      setAreaLoader(false);
      setIsSuccessModal(false);
    } else {
      setAreaLoader(false);
    }
  };

  const handleDownloadVendorComplaintsData = async () => {
    setVendorComplaintLoader(true);
    setTimeout(() => {
      setVendorComplaintLoader(false);
    }, 50000);
    const data = {
      society: fetchSocietyId(),
      vendor: activePerformingVendor?.[0]?._id,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getVendorComplaintData,
      data
    );
    if (res?.success) {
      toast.success("Lowest performing vendors data downloaded successfully!");
      setVendorComplaintLoader(false);
      setIsSuccessModal(false);
    } else {
      setVendorComplaintLoader(false);
    }
  };

  const downloadSocietyWiseQuickView = () => {
    const temp = [];
    vendorPerformanceComplaintsData.forEach((data) =>
      temp.push({
        totalComplaints: data?.totalComplaints,
        ClosedComplaints: data?.closedComplaints,
        withInSla: data?.inTime,
        beyondSla: data?.outOfTime,
        withInSlaPercentage: data?.totalComplaints
          ? `${((data.inTime / data.totalComplaints) * 100).toFixed(2)}%`
          : "0.00%",
        beyondSlaPercentage: data?.totalComplaints
          ? `${((data.outOfTime / data.totalComplaints) * 100).toFixed(2)}%`
          : "0.00%",
      })
    );
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "totalComplaints",
        name: "Total Complaints",
      },
      {
        key: "ClosedComplaints",
        name: "Closed Complaints",
      },
      {
        key: "withInSla",
        name: "With In SLA",
      },
      {
        key: "withInSlaPercentage",
        name: "With In SLA Percentage",
      },
      {
        key: "beyondSla",
        name: "Beyound SLA",
      },
      {
        key: "beyondSlaPercentage",
        name: "Beyound SLA Percentage",
      },
    ]);
    setGraphTypeName("Society Wise Complaints");
  };

  const handleDownloadSocietyWiseComplaintsData = async () => {
    setsocietyWiseComplaintsDataLoader(true);
    setTimeout(() => {
      setsocietyWiseComplaintsDataLoader(false);
    }, 50000);
    const data = {
      society: fetchSocietyId(),
      category: selectCategory,
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getSocietyWiseComplaintData,
      data
    );
    if (res?.success) {
      toast.success("CSV downloaded successfully!");
      setsocietyWiseComplaintsDataLoader(false);
    } else {
      setsocietyWiseComplaintsDataLoader(false);
    }
  };

  const downloadHighPerformingVendorData = () => {
    const temp = [];
    temp.push({
      totalComplaints: vendorPerformanceData?.allComplaintsVendor,
      ClosedComplaints: vendorPerformanceData?.closeComplaintVendor,
      withInSla: vendorPerformanceData?.inTime,
      beyondSla: vendorPerformanceData?.outTime,
      withInSlaPercentage:
        vendorPerformanceData?.allComplaintsVendor > 0
          ? `${(
              (vendorPerformanceData?.inTime /
                vendorPerformanceData?.allComplaintsVendor) *
              100
            ).toFixed(2)}%`
          : "0.00%",
      beyondSlaPercentage:
        vendorPerformanceData?.allComplaintsVendor > 0
          ? `${(
              (vendorPerformanceData?.outTime /
                vendorPerformanceData?.allComplaintsVendor) *
              100
            ).toFixed(2)}%`
          : "0.00%",
    });
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "totalComplaints",
        name: "Total Complaints",
      },
      {
        key: "ClosedComplaints",
        name: "Closed Complaints",
      },
      {
        key: "withInSla",
        name: "With In SLA",
      },
      {
        key: "withInSlaPercentage",
        name: "With In SLA Percentage",
      },
      {
        key: "beyondSla",
        name: "Beyound SLA",
      },
      {
        key: "beyondSlaPercentage",
        name: "Beyound SLA Percentage",
      },
    ]);
    setGraphTypeName("Society Wise Complaints");
  };
  const downloadLowPerformingVendorData = () => {
    const temp = [];
    temp.push({
      totalComplaints: vendorPerformanceData?.totalLowest,
      ClosedComplaints: vendorPerformanceData?.closeComplaintLowest,
      withInSla: vendorPerformanceData?.lowestInTime,
      beyondSla: vendorPerformanceData?.lowestOutTime,
      withInSlaPercentage:
        vendorPerformanceData?.totalLowest > 0
          ? `${(
              (vendorPerformanceData?.lowestInTime /
                vendorPerformanceData?.totalLowest) *
              100
            ).toFixed(2)}%`
          : "0.00%",
      beyondSlaPercentage:
        vendorPerformanceData?.totalLowest > 0
          ? `${(
              (vendorPerformanceData?.lowestOutTime /
                vendorPerformanceData?.totalLowest) *
              100
            ).toFixed(2)}%`
          : "0.00%",
    });
    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "totalComplaints",
        name: "Total Complaints",
      },
      {
        key: "ClosedComplaints",
        name: "Closed Complaints",
      },
      {
        key: "withInSla",
        name: "With In SLA",
      },
      {
        key: "withInSlaPercentage",
        name: "With In SLA Percentage",
      },
      {
        key: "beyondSla",
        name: "Beyound SLA",
      },
      {
        key: "beyondSlaPercentage",
        name: "Beyound SLA Percentage",
      },
    ]);
    setGraphTypeName("Society Wise Complaints");
  };

  const downloadMostRaisedCategoryCountReport = () => {
    const temp = [];
    mostRaisedCategory.forEach((data) =>
      temp.push({
        categoryName: data?.categoryName,
        categoryCount: data?.categoryCount,
      })
    );

    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "categoryName",
        name: "Category Name",
      },
      {
        key: "categoryCount",
        name: "Category Count",
      },
    ]);
    setGraphTypeName("Most Raised Categories");
  };

  const downloadMostRaisedCategoryPlantCountReport = () => {
    const temp = [];
    mostRaisedCategoryPlant.forEach((data) =>
      temp.push({
        categoryName: data?.categoryName,
        categoryCount: data?.categoryCount,
      })
    );

    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "categoryName",
        name: "Category Name",
      },
      {
        key: "categoryCount",
        name: "Category Count",
      },
    ]);
    setGraphTypeName("Most Raised Categories Plant");
  };

  const downloadMostRaisedPropertyCountReport = () => {
    const temp = [];
    mostRaisedProperty.forEach((data) =>
      temp.push({
        propertyName: data?.property_details?.name,
        propertyCount: data?.count,
      })
    );

    setCsvData([...temp]);
    setDownloadCsv(true);
    setHeaders([
      {
        key: "propertyName",
        name: "Property Name",
      },
      {
        key: "propertyCount",
        name: "Property Count",
      },
    ]);
    setGraphTypeName("Most Raised Properties");
  };

  const handleDownloadPropertyWiseComplaintsData = async () => {
    setPropertyWiseComplaintsDataLoader(true);
    setTimeout(() => {
      setPropertyWiseComplaintsDataLoader(false);
    }, 50000);
    const data = {
      society: fetchSocietyId(),
      properties: mostRaisedProperty.map((data) => data.propertyId),
      startDate: localStorage.getItem("fromDateforQuickView")
        ? localStorage.getItem("fromDateforQuickView")
        : startDate,
      endDate: localStorage.getItem("toDateforQuickView")
        ? localStorage.getItem("toDateforQuickView")
        : endDate,
    };
    const res = await CsvApi(
      "post",
      AllMISCSVRoutes?.getPropertyWiseComplaintData,
      data
    );
    if (res?.success) {
      toast.success("CSV downloaded successfully!");
      setPropertyWiseComplaintsDataLoader(false);
    } else {
      setPropertyWiseComplaintsDataLoader(false);
    }
  };

  return (
    <>
      <Grid
        container
        item
        spacing={2}
        xl={3}
        lg={3}
        md={4}
        sm={12}
        xs={12}
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12}>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 180,
              marginTop: 33,
              padding: "6px 14px",
            }}
          >
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {plant ? "Plant" : "Society"} Wise Quick View
              </Typography>

              <div class="dropdown">
                <button
                  class="btn"
                  onClick={() =>
                    setSocietyWiseComplaintsDataDropdown(
                      !societyWiseComplaintsDataDropdown
                    )
                  }
                >
                  {societyWiseComplaintsDataLoader ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : (
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                        stroke="#0C8CE9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {societyWiseComplaintsDataDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                    style={{ right: "0" }}
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          downloadSocietyWiseQuickView();
                          setSocietyWiseComplaintsDataDropdown(false);
                        }}
                      >
                        Download Count Report
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          handleDownloadSocietyWiseComplaintsData();
                          setSocietyWiseComplaintsDataDropdown(false);
                        }}
                      >
                        Download Details Report
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Total Complaints
              </Typography>
              <Typography
                style={{
                  color: "#0C8CE9",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {vendorPerformanceComplaintsData?.[0]?.totalComplaints || 0}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Closed Complaints
              </Typography>
              <Typography
                style={{
                  color: "red",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {vendorPerformanceComplaintsData?.[0]?.closedComplaints || 0}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Within SLA
              </Typography>
              <Typography
                style={{
                  color: "#00C172",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {vendorPerformanceComplaintsData?.[0]?.inTime || 0}&nbsp;(
                {vendorPerformanceComplaintsData?.[0]?.totalComplaints
                  ? (
                      (vendorPerformanceComplaintsData[0].inTime /
                        vendorPerformanceComplaintsData[0].totalComplaints) *
                      100
                    ).toFixed(2)
                  : "0.00"}
                %)
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Beyond SLA
              </Typography>
              <Typography
                style={{
                  color: "#EE383D",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {vendorPerformanceComplaintsData?.[0]?.outOfTime || 0}&nbsp;(
                {vendorPerformanceComplaintsData?.[0]?.totalComplaints
                  ? (
                      (vendorPerformanceComplaintsData[0].outOfTime /
                        vendorPerformanceComplaintsData[0].totalComplaints) *
                      100
                    ).toFixed(2)
                  : "0.00"}
                %)
              </Typography>
            </div>
          </div>

          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              marginTop: 20,
              padding: "6px 14px",
            }}
          >
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <div class="dropdown">
                <button
                  class="btn focus-none px-0"
                  onClick={() =>
                    setPerformingVendorDropdown(!performingVendorDropdown)
                  }
                >
                  <Typography
                    className="d-flex gap-1 text-start"
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {performingVendorToggle}
                    {performingVendorDropdown ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-up"
                        viewBox="0 0 16 16"
                        style={{ marginTop: "2px" }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                        style={{ marginTop: "2px" }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    )}
                  </Typography>
                </button>
                {performingVendorDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          setPerformingVendorToggle(
                            "Highest Performing Vendor"
                          );
                          setPerformingVendorDropdown(false);
                          setActivePerformingVendor(
                            vendorPerformanceData?.highestRatedVendor
                          );
                        }}
                      >
                        Highest Performing Vendor
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          setPerformingVendorToggle("Lowest Performing Vendor");
                          setPerformingVendorDropdown(false);
                          setActivePerformingVendor(
                            vendorPerformanceData?.lowestRatedVendor
                          );
                        }}
                      >
                        Lowest Performing Vendor
                      </span>
                    </li>
                  </ul>
                )}
              </div>

              <div class="dropdown">
                <button
                  class="btn"
                  onClick={() =>
                    setPerformingVendorComplaintsDropdown(
                      !performingVendorComplaintsDropdown
                    )
                  }
                >
                  {vendorComplaintLoader ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : (
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                        stroke="#0C8CE9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {performingVendorComplaintsDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                    style={{ right: "0" }}
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          if (
                            performingVendorToggle ===
                            "Highest Performing Vendor"
                          ) {
                            downloadHighPerformingVendorData();
                          } else {
                            downloadLowPerformingVendorData();
                          }
                          setPerformingVendorComplaintsDropdown(false);
                        }}
                      >
                        Download Count Report
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          handleDownloadVendorComplaintsData();
                          setPerformingVendorComplaintsDropdown(false);
                        }}
                      >
                        Download Details Report
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {vendorPerformanceDataLoader ? (
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{ height: "100px" }}
              >
                <LoadingOutlined
                  style={{
                    fontSize: 30,
                    color: "#2f45c5",
                    marginLeft: 15,
                  }}
                />
              </div>
            ) : (
              <>
                {performingVendorToggle === "Highest Performing Vendor" && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: 10 }}
                  >
                    <div className="d-flex gap-2">
                      <Avatar
                        className={classes.avatar}
                        src={
                          vendorPerformanceData?.highestRatedVendor?.[0]?.image
                            ? vendorPerformanceData?.highestRatedVendor?.[0]
                                ?.image
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt={
                          vendorPerformanceData?.highestRatedVendor?.[0]?.name
                        }
                        height="40px"
                        width="40px"
                      />
                      <div className="d-flex flex-column">
                        <Typography
                          style={{
                            color: "#141414",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "16px",
                          }}
                        >
                          {vendorPerformanceData?.highestRatedVendor?.[0]?.name}
                        </Typography>
                        <Typography
                          style={{
                            color: "#979797",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "14px",
                          }}
                        >
                          {
                            vendorPerformanceData?.highestRatedVendor?.[0]
                              ?.category?.categoryName
                          }
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <Typography
                        style={{
                          color: "#000000",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          textAlign: "end",
                        }}
                      >
                        {vendorPerformanceData?.highestRatedVendor?.[0]?.averageRating?.toFixed(
                          2
                        )}
                        <br />
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.24325 7.33992L1.86325 8.26492L1.75025 8.28792C1.57919 8.33333 1.42325 8.42333 1.29835 8.54872C1.17344 8.67412 1.08406 8.83041 1.03932 9.00165C0.994575 9.17289 0.996081 9.35293 1.04368 9.5234C1.09128 9.69386 1.18327 9.84864 1.31025 9.97192L5.93225 14.4709L4.84225 20.8259L4.82925 20.9359C4.81878 21.1128 4.85552 21.2894 4.9357 21.4474C5.01589 21.6055 5.13664 21.7394 5.28559 21.8354C5.43454 21.9315 5.60634 21.9862 5.7834 21.994C5.96046 22.0018 6.13642 21.9625 6.29325 21.8799L11.9993 18.8799L17.6923 21.8799L17.7923 21.9259C17.9573 21.9909 18.1367 22.0109 18.312 21.9837C18.4873 21.9565 18.6522 21.8832 18.7898 21.7712C18.9275 21.6592 19.0328 21.5127 19.0951 21.3466C19.1574 21.1804 19.1743 21.0008 19.1443 20.8259L18.0533 14.4709L22.6773 9.97092L22.7553 9.88592C22.8667 9.74869 22.9397 9.58438 22.967 9.40972C22.9942 9.23506 22.9747 9.0563 22.9103 8.89165C22.846 8.72701 22.7392 8.58235 22.6007 8.47244C22.4623 8.36252 22.2972 8.29126 22.1223 8.26592L15.7423 7.33992L12.8903 1.55992C12.8077 1.39246 12.68 1.25144 12.5214 1.15283C12.3629 1.05422 12.1799 1.00195 11.9933 1.00195C11.8066 1.00195 11.6236 1.05422 11.4651 1.15283C11.3065 1.25144 11.1788 1.39246 11.0963 1.55992L8.24325 7.33992Z"
                            fill="#FAD02D"
                          />
                        </svg>
                      </Typography>
                    </div>
                  </div>
                )}
                {performingVendorToggle === "Lowest Performing Vendor" && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: 10 }}
                  >
                    <div className="d-flex gap-2">
                      <Avatar
                        className={classes.avatar}
                        src={
                          vendorPerformanceData?.lowestRatedVendor?.[0]?.image
                            ? vendorPerformanceData?.lowestRatedVendor?.[0]
                                ?.image
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt={
                          vendorPerformanceData?.lowestRatedVendor?.[0]?.name
                        }
                        height="40px"
                        width="40px"
                      />
                      <div className="d-flex flex-column">
                        <Typography
                          style={{
                            color: "#141414",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "16px",
                          }}
                        >
                          {vendorPerformanceData?.lowestRatedVendor?.[0]?.name}
                        </Typography>
                        <Typography
                          style={{
                            color: "#979797",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "14px",
                          }}
                        >
                          {
                            vendorPerformanceData?.lowestRatedVendor?.[0]
                              ?.category?.categoryName
                          }
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <Typography
                        style={{
                          color: "#000000",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          textAlign: "end",
                        }}
                      >
                        {vendorPerformanceData?.lowestRatedVendor?.[0]?.averageRating?.toFixed(
                          2
                        )}
                        <br />
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.24325 7.33992L1.86325 8.26492L1.75025 8.28792C1.57919 8.33333 1.42325 8.42333 1.29835 8.54872C1.17344 8.67412 1.08406 8.83041 1.03932 9.00165C0.994575 9.17289 0.996081 9.35293 1.04368 9.5234C1.09128 9.69386 1.18327 9.84864 1.31025 9.97192L5.93225 14.4709L4.84225 20.8259L4.82925 20.9359C4.81878 21.1128 4.85552 21.2894 4.9357 21.4474C5.01589 21.6055 5.13664 21.7394 5.28559 21.8354C5.43454 21.9315 5.60634 21.9862 5.7834 21.994C5.96046 22.0018 6.13642 21.9625 6.29325 21.8799L11.9993 18.8799L17.6923 21.8799L17.7923 21.9259C17.9573 21.9909 18.1367 22.0109 18.312 21.9837C18.4873 21.9565 18.6522 21.8832 18.7898 21.7712C18.9275 21.6592 19.0328 21.5127 19.0951 21.3466C19.1574 21.1804 19.1743 21.0008 19.1443 20.8259L18.0533 14.4709L22.6773 9.97092L22.7553 9.88592C22.8667 9.74869 22.9397 9.58438 22.967 9.40972C22.9942 9.23506 22.9747 9.0563 22.9103 8.89165C22.846 8.72701 22.7392 8.58235 22.6007 8.47244C22.4623 8.36252 22.2972 8.29126 22.1223 8.26592L15.7423 7.33992L12.8903 1.55992C12.8077 1.39246 12.68 1.25144 12.5214 1.15283C12.3629 1.05422 12.1799 1.00195 11.9933 1.00195C11.8066 1.00195 11.6236 1.05422 11.4651 1.15283C11.3065 1.25144 11.1788 1.39246 11.0963 1.55992L8.24325 7.33992Z"
                            fill="#FAD02D"
                          />
                        </svg>
                      </Typography>
                    </div>
                  </div>
                )}
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: 8 }}
                >
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: "16px",
                    }}
                  >
                    Total Complaints
                  </Typography>
                  <Typography
                    style={{
                      color: "#0C8CE9",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    {performingVendorToggle === "Highest Performing Vendor"
                      ? vendorPerformanceData?.allComplaintsVendor
                      : vendorPerformanceData?.totalLowest}
                  </Typography>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: 8 }}
                >
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: "16px",
                    }}
                  >
                    Closed Complaints
                  </Typography>
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    {performingVendorToggle === "Highest Performing Vendor"
                      ? vendorPerformanceData?.closeComplaintVendor
                      : vendorPerformanceData?.closeComplaintLowest}
                  </Typography>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: 8 }}
                >
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: "16px",
                    }}
                  >
                    Within SLA
                  </Typography>
                  <Typography
                    style={{
                      color: "#00C172",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    {performingVendorToggle === "Highest Performing Vendor"
                      ? vendorPerformanceData?.inTime
                      : vendorPerformanceData?.lowestInTime}
                    &nbsp;(
                    {performingVendorToggle === "Highest Performing Vendor"
                      ? vendorPerformanceData?.allComplaintsVendor > 0
                        ? (
                            (vendorPerformanceData?.inTime /
                              vendorPerformanceData?.allComplaintsVendor) *
                            100
                          ).toFixed(2)
                        : "0.00"
                      : vendorPerformanceData?.totalLowest > 0
                      ? (
                          (vendorPerformanceData?.lowestInTime /
                            vendorPerformanceData?.totalLowest) *
                          100
                        ).toFixed(2)
                      : "0.00"}
                    %)
                  </Typography>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: 8 }}
                >
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: "16px",
                    }}
                  >
                    Beyond SLA
                  </Typography>
                  <Typography
                    style={{
                      color: "#EE383D",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    {performingVendorToggle === "Highest Performing Vendor"
                      ? vendorPerformanceData?.outTime
                      : vendorPerformanceData?.lowestOutTime}
                    &nbsp;(
                    {performingVendorToggle === "Highest Performing Vendor"
                      ? vendorPerformanceData?.allComplaintsVendor > 0
                        ? (
                            (vendorPerformanceData?.outTime /
                              vendorPerformanceData?.allComplaintsVendor) *
                            100
                          ).toFixed(2)
                        : "0.00"
                      : vendorPerformanceData?.totalLowest > 0
                      ? (
                          (vendorPerformanceData?.lowestOutTime /
                            vendorPerformanceData?.totalLowest) *
                          100
                        ).toFixed(2)
                      : "0.00"}
                    %)
                  </Typography>
                </div>
              </>
            )}
          </div>

          {/* <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              height: 200,
              marginTop: 20,
              padding: "6px 14px",
            }}
          >
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Total Complaints - {categoryName}
            </Typography>
            <Typography
              style={{
                color: "#000000",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "18px",
                textAlign: "center",
                marginTop: 15,
              }}
            >
              {complaintCounts?.totalComplaints}
            </Typography>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 15 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Assigned
              </Typography>
              <Typography
                style={{
                  color: "#0C8CE9",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {complaintCounts?.totalAssignedComplaints}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Resolved
              </Typography>
              <Typography
                style={{
                  color: "#00C172",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {complaintCounts?.totalResolvedComplaints}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 8 }}
            >
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "16px",
                }}
              >
                Closed
              </Typography>
              <Typography
                style={{
                  color: "#EE383D",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {complaintCounts?.totalClosedComplaints}
              </Typography>
            </div>
          </div> */}

          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              marginTop: 20,
              padding: "6px 14px",
            }}
          >
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Most Raised Category
              </Typography>

              <div class="dropdown">
                <button
                  class="btn"
                  onClick={() =>
                    setMostRaisedCategoryDropdown(!mostRaisedCategoryDropdown)
                  }
                >
                  {/* {vendorComplaintLoader ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : ( */}
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                      stroke="#0C8CE9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {/* )} */}
                </button>
                {mostRaisedCategoryDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                    style={{ right: "0" }}
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          downloadMostRaisedCategoryCountReport();
                          setMostRaisedCategoryDropdown(false);
                        }}
                      >
                        Download Count Report
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          handlesetCategoriesIds();
                          setMostRaisedCategoryDropdown(false);
                        }}
                      >
                        Download Details Report
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {mostRaisedCategoryLoader ? (
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{ height: "100px" }}
              >
                <LoadingOutlined
                  style={{
                    fontSize: 30,
                    color: "#2f45c5",
                    marginLeft: 15,
                  }}
                />
              </div>
            ) : (
              <>
                {mostRaisedCategory?.map((category, index) => {
                  return (
                    <div class="accordion" id="most_raised_category">
                      <div class="accordion-item border-0">
                        <p class="accordion-header" id={category?._id}>
                          <button
                            className="accordion-button custom-accordion-button py-2 collapsed px-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${category?._id}`}
                            aria-expanded="false"
                            aria-controls={`collapse${category?._id}`}
                          >
                            <div className="d-flex align-items-center gap-3 justify-content-between w-100">
                              <span
                                style={{
                                  color: "#979797",
                                  fontFamily: "Poppins",
                                  fontWeight: 300,
                                  fontSize: "16px",
                                }}
                              >
                                {index + 1}.&nbsp;{category?.categoryName}
                              </span>

                              <span
                                style={{
                                  color: "#0C8CE9",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                }}
                              >
                                {category?.categoryCount}
                              </span>
                            </div>
                          </button>
                        </p>
                        <div
                          id={`collapse${category?._id}`}
                          class="accordion-collapse collapse"
                          aria-labelledby={category?._id}
                          data-bs-parent="#most_raised_category"
                        >
                          <div class="accordion-body">
                            <div
                              className="d-flex flex-column gap-3 overflow-auto scroll-container"
                              style={{ maxHeight: "200px" }}
                            >
                              {category?.subcategories?.map(
                                (subCategory, index) => {
                                  return (
                                    <div
                                      className="d-flex justify-content-between gap-3"
                                      key={subCategory?._id}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: "Poppins",
                                          fontWeight: 300,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {index + 1}.&nbsp;
                                        {subCategory?.subcategoryName}
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "#0C8CE9",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {subCategory?.subCategoryCount}
                                      </Typography>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          {plant && (
            <div
              style={{
                border: "1px solid #E1E1E1",
                borderRadius: "10px",
                marginTop: 20,
                padding: "6px 14px",
              }}
            >
              <div className="d-flex align-items-center gap-3 justify-content-between">
                <Typography
                  style={{
                    color: "#979797",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                >
                  Most Raised Area
                </Typography>

                <div class="dropdown">
                  <button
                    class="btn"
                    onClick={() =>
                      setMostRaisedCategoryPlantDropdown(
                        !mostRaisedCategoryPlantDropdown
                      )
                    }
                  >
                    {/* {vendorComplaintLoader ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : ( */}
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                        stroke="#0C8CE9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* )} */}
                  </button>
                  {mostRaisedCategoryPlantDropdown && (
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdown_Vendor_SLA_Button"
                      style={{ right: "0" }}
                    >
                      <li>
                        <span
                          class="dropdown-item pointer"
                          onClick={() => {
                            downloadMostRaisedCategoryPlantCountReport();
                            setMostRaisedCategoryPlantDropdown(false);
                          }}
                        >
                          Download Count Report
                        </span>
                      </li>
                      <li>
                        <span
                          class="dropdown-item pointer"
                          onClick={() => {
                            handlesetCategoriesPlantIds();
                            setMostRaisedCategoryPlantDropdown(false);
                          }}
                        >
                          Download Details Report
                        </span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>

              {mostRaisedCategoryPlantLoader ? (
                <div
                  className="d-flex align-items-center justify-content-center w-100"
                  style={{ height: "100px" }}
                >
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: "#2f45c5",
                      marginLeft: 15,
                    }}
                  />
                </div>
              ) : (
                <>
                  {mostRaisedCategoryPlant?.map((category, index) => {
                    return (
                      <div class="accordion" id="most_raised_category_plant">
                        <div class="accordion-item border-0">
                          <p class="accordion-header" id={category?._id}>
                            <button
                              className="accordion-button custom-accordion-button py-2 collapsed px-0"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${category?._id}`}
                              aria-expanded="false"
                              aria-controls={`collapse${category?._id}`}
                            >
                              <div className="d-flex align-items-center gap-3 justify-content-between w-100">
                                <span
                                  style={{
                                    color: "#979797",
                                    fontFamily: "Poppins",
                                    fontWeight: 300,
                                    fontSize: "16px",
                                  }}
                                >
                                  {index + 1}.&nbsp;{category?.name}
                                </span>

                                <span
                                  style={{
                                    color: "#0C8CE9",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                  }}
                                >
                                  {category?.areaCount}
                                </span>
                              </div>
                            </button>
                          </p>
                          <div
                            id={`collapse${category?._id}`}
                            class="accordion-collapse collapse"
                            aria-labelledby={category?._id}
                            data-bs-parent="#most_raised_category_plant"
                          >
                            <div class="accordion-body">
                              <div
                                className="d-flex flex-column gap-3 overflow-auto scroll-container"
                                style={{ maxHeight: "200px" }}
                              >
                                {category?.subareas?.map(
                                  (subCategory, index) => {
                                    return (
                                      <div
                                        className="d-flex justify-content-between gap-3"
                                        key={subCategory?._id}
                                      >
                                        <Typography
                                          style={{
                                            fontFamily: "Poppins",
                                            fontWeight: 300,
                                            fontSize: "16px",
                                          }}
                                        >
                                          {index + 1}.&nbsp;
                                          {subCategory?.name}
                                        </Typography>
                                        <Typography
                                          style={{
                                            color: "#0C8CE9",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                          }}
                                        >
                                          {subCategory?.subareaCount}
                                        </Typography>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          )}

          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: "10px",
              marginTop: 20,
              padding: "6px 14px",
            }}
          >
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Most Raised {handlePropertyName()}
              </Typography>

              <div class="dropdown">
                <button
                  class="btn"
                  onClick={() =>
                    setMostRaisedPropertyDropdown(!mostRaisedPropertyDropdown)
                  }
                >
                  {propertyWiseComplaintsDataLoader ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 30,
                        color: "#2f45c5",
                        marginLeft: 15,
                      }}
                    />
                  ) : (
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33398 22.6663V25.333C5.33398 26.0403 5.61494 26.7185 6.11503 27.2186C6.61513 27.7187 7.29341 27.9997 8.00065 27.9997H24.0006C24.7079 27.9997 25.3862 27.7187 25.8863 27.2186C26.3864 26.7185 26.6673 26.0403 26.6673 25.333V22.6663M9.33398 14.6663L16.0006 21.333M16.0006 21.333L22.6673 14.6663M16.0006 21.333V5.33301"
                        stroke="#0C8CE9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {mostRaisedPropertyDropdown && (
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdown_Vendor_SLA_Button"
                    style={{ right: "0" }}
                  >
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          downloadMostRaisedPropertyCountReport();
                          setMostRaisedPropertyDropdown(false);
                        }}
                      >
                        Download Count Report
                      </span>
                    </li>
                    <li>
                      <span
                        class="dropdown-item pointer"
                        onClick={() => {
                          handleDownloadPropertyWiseComplaintsData();
                          setMostRaisedPropertyDropdown(false);
                        }}
                      >
                        Download Details Report
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {mostRaisedProperty?.map((data) => {
              return (
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: 20 }}
                >
                  <Typography
                    style={{
                      color: "#979797",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: "16px",
                    }}
                  >
                    {data?.property_details?.name}
                  </Typography>
                  <Typography
                    style={{
                      color: "#0C8CE9",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    {data?.count}
                  </Typography>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>

      {isSuccessModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ borderRadius: "0.5rem" }}>
              <div className="modal-body p-4">
                <div className="d-flex flex-column gap-3 align-items-center">
                  <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                    <svg
                      width="90"
                      height="90"
                      viewBox="0 0 136 136"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M76.1295 4.82006C71.9283 -0.311124 64.0815 -0.311106 59.8809 4.82006L53.9297 12.0888C53.5218 12.5869 52.8414 12.7693 52.2391 12.5418L43.451 9.22238C37.2473 6.8792 30.4517 10.8025 29.3791 17.3468L27.8597 26.6172C27.7556 27.2526 27.2575 27.7507 26.6221 27.8548L17.3517 29.3742C10.8075 30.4468 6.88403 37.2423 9.22727 43.4461L12.5466 52.2342C12.7742 52.8366 12.5918 53.517 12.0936 53.9248L4.82495 59.8758C-0.306239 64.077 -0.306227 71.9238 4.82495 76.125L12.0936 82.0758C12.5918 82.4837 12.7742 83.1642 12.5466 83.7666L9.22727 92.5547C6.88409 98.7581 10.8074 105.554 17.3517 106.627L26.6221 108.146C27.2575 108.25 27.7556 108.748 27.8597 109.384L29.3791 118.654C30.4517 125.198 37.2472 129.121 43.451 126.778L52.2391 123.459C52.8414 123.232 53.5218 123.413 53.9297 123.912L59.8809 131.18C64.0815 136.312 71.9283 136.312 76.1295 131.18L82.0804 123.912C82.4883 123.413 83.1687 123.232 83.7711 123.459L92.5593 126.778C98.7633 129.121 105.559 125.198 106.631 118.654L108.151 109.384C108.255 108.748 108.753 108.25 109.388 108.146L118.659 106.627C125.203 105.554 129.126 98.7581 126.783 92.5547L123.464 83.7666C123.236 83.1642 123.419 82.4837 123.917 82.0758L131.186 76.125C136.317 71.9238 136.317 64.077 131.186 59.8758L123.917 53.9248C123.419 53.517 123.236 52.8366 123.464 52.2342L126.783 43.4461C129.126 37.2423 125.203 30.4468 118.659 29.3742L109.388 27.8548C108.753 27.7507 108.255 27.2526 108.151 26.6172L106.631 17.3468C105.559 10.8026 98.7633 6.87914 92.5593 9.22238L83.7711 12.5418C83.1687 12.7693 82.4883 12.5869 82.0804 12.0888L76.1295 4.82006Z"
                        fill="#4DC4FF"
                      />
                    </svg>
                    <svg
                      width="56"
                      height="32"
                      viewBox="0 0 76 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: "-60px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M74.1818 1.818C75.9392 3.57534 75.9392 6.42462 74.1818 8.18196L32.1818 50.1818C31.3382 51.026 30.1934 51.5 29 51.5C27.8066 51.5 26.6618 51.026 25.818 50.1818L1.818 26.1818C0.0606651 24.4244 0.0606651 21.5756 1.818 19.8182C3.57534 18.0608 6.42463 18.0608 8.18197 19.8182L29 40.6358L67.8182 1.818C69.5756 0.0606651 72.4244 0.0606651 74.1818 1.818Z"
                        fill="#ECEFF1"
                      />
                    </svg>
                  </div>
                  <Typography
                    style={{
                      fontSize: 23,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Success !
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: 15,
                      color: "#6c757d",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Data is successfully fetched and ready for download
                  </Typography>
                  <div className="d-flex align-items-center gap-5 w-100">
                    <button
                      className="btn btn-outline-danger d-flex align-items-center justify-content-center w-100"
                      style={{ borderRadius: "0.5rem" }}
                      onClick={() => setIsSuccessModal(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                      </svg>
                      &nbsp;Cancel
                    </button>
                    <button
                      className="btn btn-outline-success d-flex align-items-center justify-content-center w-100"
                      style={{ borderRadius: "0.5rem" }}
                      onClick={() => {
                        isArea
                          ? handleDownloadAreaComplaintData()
                          : handleDownloadCategoryComplaintData();
                      }}
                    >
                      {loader && areaLoader ? (
                        <LoadingOutlined
                          style={{
                            fontSize: 30,
                            color: "#2f45c5",
                            marginLeft: 15,
                          }}
                        />
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-download"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                          </svg>
                          &nbsp;Download
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PerformanceCounts;
