import axios from "axios";
import { BASE_URL } from "../utils/base";
import { decrypyData } from "../utils/encryption";
import { resolveErrorMessage } from "../services/service";
import { toast } from "react-toastify";

const API = axios.create({ baseURL: BASE_URL });
API.interceptors.request.use((req) => {
  if (localStorage.getItem("refreshToken")) {
    req.headers.token = `${JSON.parse(localStorage.getItem("refreshToken"))}`;
  }
  req.headers.version = decrypyData(localStorage.getItem("version"));
  req.headers.platform = "dashboard";
  return req;
});

const CsvApi = async (type, route, data) => {
  try {
    let response;

    if (type === "post") {
      response = await API.post(route, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/csv",
        },
        responseType: "blob", // Required to handle binary data
      });
    } else {
      response = await API.get(route, {
        params: data, // For GET, pass data as query params
        headers: {
          "Content-Type": "application/json",
          Accept: "text/csv",
        },
        responseType: "blob",
      });
    }

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.csv"; // Set the file name
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    const success = false;
    toast.error(
      `Something went wrong, ${resolveErrorMessage(error)}, ${
        error?.response?.status
      }`
    );
    return {
      success,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default CsvApi;
