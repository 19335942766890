import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({color,size}) => {
  return (
      <p style={{marginLeft:7}}>
      <ClipLoader color={color}  size={size}  />
      </p>
  );
};

export default Loader;
