import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  IconButton,
  Paper,
  Typography,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import useStyles from "./styles";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Close } from "@material-ui/icons";
import ApiCall from "../../../api/ApiCall";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import {
  AllMemberRoutes,
  complaintSubcategoryRoutes,
} from "../../../api/Routes";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingOutlined } from "@ant-design/icons";
import { DomainAddOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const AddPriorityContactPlant = ({
  addContactModal,
  setaddContactModal,
  subcategory,
  handleGetPriorityContacts,
  allAdditionalContacts
}) => {
  const classes = useStyles();
  const [selectedRole, setselectedRole] = useState("admin");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [search, setSearch] = useState("");
  const [allUsers, setallUsers] = useState([]);

  const handleReset = () => {
    setloader2(false);
    setaddContactModal(false);
    setcheckedUserByRole({
      admin: [],
      manager:[],
      owner: [],
      tenant: [],
      vendor: [],
    });
  };

  // Store checked users by role
  const [checkedUserByRole, setcheckedUserByRole] = useState({
    admin: [],
    manager:[],
    owner: [],
    tenant: [],
    vendor: [],
  });

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleGetAllUsers = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    const sendData = {
      role: selectedRole,
      societyId: fetchSocietyId(),
      searchKey: search,
    };
    const res = await ApiCall(
      "post",
      AllMemberRoutes.getAllUserFromSociety,
      sendData
    );
    if (res?.success) {
      if (selectedRole === "admin") {
        setallUsers(res?.persons?.admins);
      } 
      else if (selectedRole === "manager") {
        setallUsers(res?.persons?.managers);
      }
      else if (selectedRole === "owner") {
        setallUsers(res?.persons?.owners);
      } else if (selectedRole === "tenant") {
        setallUsers(res?.persons?.tenants);
      } else {
        setallUsers(res?.persons?.vendors);
      }
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllUsers();
  }, [search, selectedRole]);

   // Set checked users based on plantEmergencyContact
   useEffect(() => {
    if (allUsers.length > 0) {
      const existingUsers = allAdditionalContacts?.map((data) => data?._id)
      const preCheckedUsers = allUsers
        .filter((user) => existingUsers.includes(user._id))
        .map((user) => user._id);

      setcheckedUserByRole((prevCheckedUserByRole) => ({
        ...prevCheckedUserByRole,
        [selectedRole]: preCheckedUsers,
      }));
    }
  }, [allUsers, allAdditionalContacts, selectedRole]);

  const handleUserCheckboxChange = (residentId) => {
    setcheckedUserByRole((prevCheckedUserByRole) => {
      const updatedCheckedUsers = prevCheckedUserByRole[selectedRole].includes(
        residentId
      )
        ? prevCheckedUserByRole[selectedRole].filter((id) => id !== residentId)
        : [...prevCheckedUserByRole[selectedRole], residentId];

      return {
        ...prevCheckedUserByRole,
        [selectedRole]: updatedCheckedUsers,
      };
    });
  };

  const handleSelectAllCheckboxChange = useCallback(() => {
    setcheckedUserByRole((prevCheckedUserByRole) => {
      const updatedCheckedUsers = selectAllChecked
        ? []
        : allUsers?.map((user) => user._id);

      return {
        ...prevCheckedUserByRole,
        [selectedRole]: updatedCheckedUsers,
      };
    });
    setSelectAllChecked(!selectAllChecked);
  }, [selectAllChecked, allUsers, selectedRole]);

  useEffect(() => {
    if (
      checkedUserByRole[selectedRole].length === allUsers.length &&
      allUsers.length > 0
    ) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [checkedUserByRole, allUsers, selectedRole]);

  const handleAddPriorityContact = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    const allIds = Object.values(checkedUserByRole).reduce(
      (acc, curr) => [...acc, ...curr],
      []
    );
    const data = {
      societyId: fetchSocietyId(),
      subComplaintCategoryId: subcategory?._id,
      plantEmergencyContact: allIds,
    };
    const res = await ApiCall(
      "post",
      complaintSubcategoryRoutes.addPriorityContactPlant,
      data
    );
    if (res?.success) {
      toast.success("Add Priority Contact Successfully");
      handleReset();
      handleGetPriorityContacts();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <Modal open={addContactModal}>
      <Paper
        style={{
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          width: "53vw",
          maxHeight: "90vh",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Add Priority Contact
          </Typography>
          <IconButton onClick={handleReset}>
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid
          container
          style={{ marginTop: 8, justifyContent: "space-between" }}
        >
          <Grid item xs={8}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: 6,
              }}
            >
              Selected Role :
              <Select
                value={selectedRole}
                onChange={(event) => setselectedRole(event.target.value)}
                style={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderRadius: 6,
                  width: "180px",
                  height: 37,
                  fontSize: "13px",
                  padding: 8,
                  paddingLeft: 6,
                  marginLeft: 5,
                  border: "1px solid #6c757d",
                  textAlign: "center",
                }}
              >
                <MenuItem
                  selected
                  value="admin"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <ManageAccountsIcon
                    style={{ marginRight: 4, fontSize: 20 }}
                  />
                  Management
                </MenuItem>
                <MenuItem
                  selected
                  value="manager"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <ManageAccountsIcon
                    style={{ marginRight: 4, fontSize: 20 }}
                  />
                  Manager
                </MenuItem>
                <MenuItem
                  selected
                  value="owner"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <ApartmentIcon style={{ marginRight: 4, fontSize: 20 }} />
                  Owner
                </MenuItem>
                <MenuItem
                  selected
                  value="tenant"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <ApartmentIcon style={{ marginRight: 4, fontSize: 20 }} />
                  Tenant
                </MenuItem>
                <MenuItem
                  selected
                  value="vendor"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <AccountCircleIcon style={{ marginRight: 4, fontSize: 20 }} />
                  Vendor
                </MenuItem>
              </Select>
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ marginTop: -4 }}>
            <div class="box">
              <input
                type="text"
                placeholder="name or email or numb.."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                value={search}
              />
              <div className="searchIcon">
                <SearchIcon />
              </div>
            </div>
          </Grid>

          <TableContainer
            component={Paper}
            style={{ overflow: "scroll", height: "60vh", marginTop: 10 }}
          >
            {!loader && (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        className="form-check-input "
                        onChange={handleSelectAllCheckboxChange}
                        checked={selectAllChecked}
                      />
                      &nbsp; Sr. No.
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Contact
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUsers?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          className="form-check-input"
                          checked={checkedUserByRole[selectedRole]?.includes(
                            row?._id
                          )}
                          onChange={() => handleUserCheckboxChange(row?._id)}
                        />
                        &nbsp;&nbsp;
                        {index + 1} .
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "normal",
                        }}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "normal",
                        }}
                      >
                        {row?.email}
                      </TableCell>
                      
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "normal",
                        }}
                      >
                        {row?.phone}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {loader && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </div>
            )}
          </TableContainer>
        </Grid>
        {errorMessage && (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
              fontSize: "12px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "140px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            onClick={() => {
              handleAddPriorityContact();
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                {loader2 ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  <DomainAddOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                )}

                {!loader2 ? `Add Contact` : `Adding...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default AddPriorityContactPlant;
