const noticePrefix = "notice";
const discussionPrefix = "discussion";
const meetingPrefix = "meeting";
const pollPrefix = "poll";
const memberPrefix = "member";
const userPrefix = "user";
const managementPrefix = "management";
const vendorPrefix = "vendor";
const familymemberPrefix = "family";
const helpPrefix = "help";
const staffPrefix = "staff";
const visitorPrefix = "visitor";
const guardPrefix = "guard";
const timelinePrefix = "timeline";
const s2admin = "s2admin";
const s1admin = "s1admin";
const authPrefix = "auth";
const groupPrefix = "address-group";
const csvPrefix = "csv";

// ***** COMPLAINT SUBCATEGORY RELATED  ROUTES *****
export const complaintSubcategoryRoutes = {
  addPriorityContact: managementPrefix + "/add-EmergencyNumberToSubCategory",
  getPriorityContact: managementPrefix + "/getEmergencyNumber",
  changePriorityContactStatus:
    managementPrefix + "/changeEmergencyNumberStatus",
  addPriorityContactPlant: managementPrefix + "/add-emergency-contact-in-plant",
  removePriorityContactPlant:
    managementPrefix + "/remove-emergency-contact-in-plant",
};

// ***** ENTRY POINTS ROUTES *****
export const EntryPoints = {
  sendOtpForResetPassword: authPrefix + "/common-forgot-password",
  confirmOtpForResetPassword: authPrefix + "/common-validate-otp",
  confirmResetPassword: authPrefix + "/common-reset-password",
  signup: s2admin + "/admin-s1admin",
  configuration: s2admin + "/get-society-configuration",
  sendOtpForSignup: authPrefix + "/admin-vendor/send-otp",
  verifyOtpForSignup: authPrefix + "/admin-vendor/verify-otp",
  signUpAdmin: s2admin + "/admin-s1admin-with-verification",
};

// ***** MODULE ACCESS ROUTES *****
export const ModuleAccessRoutes = {
  checkModuleAccess: memberPrefix + "/check-member-by-society",
};

// ***** ALL SOCIETY RELATED ROUTES *****
export const AllSocietyRoutes = {
  getSocieties: s1admin + "/getAllSociety",
  getAllSocietyRequests: s1admin + "/admin-all-society-request",
  addSocietyRequests: s1admin + "/admin-add-society-request",
  approveOrRejectSocietyRequests:
    s1admin + "/approveOrReject-admin-society-request",
  getFloors: visitorPrefix + "/floors",
  getFlats: visitorPrefix + "/flats",
  getVendors: vendorPrefix + "/getAllVendor",
  editProperty: managementPrefix + "/edit-property",
  editFlat: managementPrefix + "/edit-flat",
  getSector: managementPrefix + "/get-sector",
};

// ***** ALL COMPLAINT UPDATE PROCESS  ROUTES *****
export const ComplaintUpdateProcessRoutes = {
  assignOrReassignComplaint: managementPrefix + "/assign-vendor",
  complaintLifeCycle: managementPrefix + "/complaint-life-cycle",
  notifyVendor: managementPrefix + "/notify-vendor",
  notifyComplainer: managementPrefix + "/notify-customer",
  changePriority: managementPrefix + "/change-priority",
  resolveOrCloseComplaint: managementPrefix + "/resolve-or-close-complaint",
  cancelComplaint: managementPrefix + "/cancel-life-cycle",
  getDataForCsv: managementPrefix + "/fetch-data-for-csv",
  addSubComplaint: managementPrefix + "/reassigned-new-complaint",
  getAllComplaint: managementPrefix + "/get-complaints",
  getComplaintDeadline: vendorPrefix + "/get-complaint-deadline",
  approveDeadline: vendorPrefix + "/approve-reject-complaint-deadline",
  updateSubSubCategory:
    managementPrefix + "/add-deadline-time-sub-sub-category",
  extendDeadline: vendorPrefix + "/extend-complaint-deadline-by-admin",
};
// ** ALL NOTICE RELATED ROUTES **
export const AllNoticeRoutes = {
  getNotices: noticePrefix + "/get-notices",
  addNotice: noticePrefix + "/add-notice",
  addNoticeType: noticePrefix + "/add-notice-type",
  getNoticeTypes: noticePrefix + "/get-notice-type",
  getIndividualNotice: noticePrefix + "/get-notice-by-noticeId",
  editNotice: noticePrefix + "/update-notice",
  deleteNotice: noticePrefix + "/delete-notice",
};

// ** ALL DISCUSSION RELATED ROUTES **
export const AllDiscussionRoutes = {
  getDiscussions: discussionPrefix + "/all-discussions",
  addDiscussion: discussionPrefix + "/add-discussion",
  getIndividualDiscussion: discussionPrefix + "/discussion-by-id",
  discussionViewInfo: discussionPrefix + "/populate-prop-view",
  addComment: discussionPrefix + "/add-comment",
  addReply: discussionPrefix + "/add-reply",
  deleteDiscussion: discussionPrefix + "/delete-discussion",
};

// ** ALL MEETING RELATED ROUTES **
export const AllMeetingRoutes = {
  getMeetings: meetingPrefix + "/get-meeting",
  addMeeting: meetingPrefix + "/add-meeting",
  addMeetingCategory: meetingPrefix + "/add-category",
  getMettingCategories: meetingPrefix + "/get-category",
  getIndividualMeeting: meetingPrefix + "/get-meeting-by-id",
  editMeeting: meetingPrefix + "/update-meeting",
  cancelMeeting: meetingPrefix + "/cancel-meeting",
};

// ** ALL POLL RELATED ROUTES **
export const AllPollRoutes = {
  getPolls: pollPrefix + "/getAllPoll",
  addPoll: pollPrefix + "/addPoll",
  submitVote: pollPrefix + "/voteByUser",
  getOptionDetails: pollPrefix + "/optionsDetailByOptionId",
  getPollDetails: pollPrefix + "/getOptionDetail",
  deletePoll: pollPrefix + "/deletePoll",
};

// ** ALL MEMBER RELATED ROUTES **
export const AllMemberRoutes = {
  getAllMembers: memberPrefix + "/get-all-members",
  giveModulePermission: memberPrefix + "/user-check",
  givePermissionMember: memberPrefix + "/add-member",
  getAllUserFromSociety: managementPrefix + "/get-all-users",
  fetchUser: userPrefix + "/get-user-By-Id",
};

// ** ALL RESIDENT RELATED ROUTES **
export const AllResidentRoutes = {
  getAllResidentRequests: userPrefix + "/resident-requests",
  changeResidentRequests: managementPrefix + "/change-request-status",
  residentChangeAddress: managementPrefix + "/resident-change-address",
  changeResidentStatus: managementPrefix + "/changeToPendingStatus",
  getRequestHistory: userPrefix + "/getRequestHistory",
  approveOrRejectVendorRequest: s2admin + "/approve-reject-approval-request",
  getAllVendorRequests: vendorPrefix + "/get-all-vendors",
  sendForApprovalVendor: s2admin + "/send-for-re-approval",
  makeManager:
    managementPrefix + "/approve-manager-request-or-convert-user-to-manager",
  getManager: managementPrefix + "/get-managers",
  removeManager: groupPrefix + "/remove-user",
};

// ** ALL FAMILYMEMBER RELATED ROUTES **
export const AllFamilyMemberRoutes = {
  getFamilyMembers: familymemberPrefix + "/getFamilyMember",
};

// ** ALL VENDOR RELATED ROUTES **
export const AllVendorRoutes = {
  getVendors: vendorPrefix + "/getAllVendor",
  addVendor: managementPrefix + "/add-vendor",
  editVendor: managementPrefix + "/edit-vendor",
  deleteVendor: managementPrefix + "/delete-vendor",
  getVendorDetails: vendorPrefix + "/fetch-vendor-by-Id",
  getVendorGraph: vendorPrefix + "/vendorGraphData",
  getVendorComplaint: vendorPrefix + "/vendor-get-complaints-admin",
  getVendorCategory: managementPrefix + "/get-vendor-cat-sub-cat",
};

// ** ALL EMERGENCYNUMBER RELATED ROUTES **
export const AllEmergencyNumberRoutes = {
  getEmergencyNumbers: helpPrefix + "/get-emergency-contacts",
  addEmergencyNumber: helpPrefix + "/add-emergency-contacts",
  editEmergencyNumber: helpPrefix + "/edit-emergency-contact",
  deleteEmergencyNumber: helpPrefix + "/delete-emergency-contact",
};

// ** ALL LOCALDIRECTORY RELATED ROUTES **
export const AllLocalDirectoryRoutes = {
  getLocalDirectories: helpPrefix + "/get-local-directory",
  addLocalDirectory: helpPrefix + "/add-local-directory",
  editLocalDirectory: helpPrefix + "/edit-local-directory",
  deleteLocalDirectory: helpPrefix + "/removeLocalDirectory",
  getLocalDirectoryCategories: helpPrefix + "/get-local-DirectoryCategory",
  addLocalDirectoryCategory: helpPrefix + "/add-local-DirectoryCategory",
  editLocalDirectoryCategory: helpPrefix + "/editLocalDirectoryCategory",
  deleteLocalDirectoryCategory: helpPrefix + "/removeLocalDirectoryCategory",
};

// ** ALL DAILYHELP RELATED ROUTES **
export const AllDailyHelpRoutes = {
  getStaffType: staffPrefix + "/get-staff-type",
  getStaffCount: staffPrefix + "/staff-count",
  addStaffType: staffPrefix + "/add-staff-type",
};

// ** ALL COMPLAINT RELATED ROUTES **
export const AllComplaintRoutes = {
  getCategories: managementPrefix + "/get-complaint-category",
  getSubCategories: managementPrefix + "/get-subcomplaint-category",
  searchUserInAddComplaint: managementPrefix + "/search-user",
  getComplaintById: userPrefix + "/get-complaint",
  getJmr: managementPrefix + "/get-jmr",
  addJmr: managementPrefix + "/add-jmr",
  editJmr: managementPrefix + "/edit-jmr",
  deleteJmr: managementPrefix + "/delete-jmr",
  JmrFreezeVendor: managementPrefix + "/freeze-vendor-jmr-by-admin",
  JmrFreezeAdmin: managementPrefix + "/freeze-jmr-by-admin",
  assignVendor: managementPrefix + "management/assign-vendor",
  changePriority: managementPrefix + "management/change-priority",
  complaintLifeCycle: managementPrefix + "management/complaint-life-cycle",
  shutDownScheduler: managementPrefix + "/management/shut-down",
  changeStatus: managementPrefix + "management/resolve-or-close-complaint",
  changeStatusToCancel: managementPrefix + "/management/cancel-life-cycle",
  createComplaintCategory:
    managementPrefix + "management/add-complaint-category",
  getAdditionalContacts: managementPrefix + "/management/getEmergencyNumber",
  createSubComplaintCategory:
    managementPrefix + "management/add-sub-complaint-category",
  getEscalationProfile:
    managementPrefix + "/management/get-catgory-autoescalate-levels",
  addAutoEscalation:
    managementPrefix + "management/add-complaintAutoEscalate-level",
  editSubComplaintCategory:
    managementPrefix + "management/edit-sub-complaint-category",
  countComplaint: managementPrefix + "management/count-complaint",
  complaintStatusGraph: managementPrefix + "management/complaintStatusGraph",
  getComplaint: managementPrefix + "management/get-complaints",
  getComplaintByIds:
    managementPrefix + "/management/get-complainsByComplainIds",
  FetchCsvData: managementPrefix + "/management/fetch-data-for-csv",
  addComplaint: userPrefix + "user/add-complaint",
  getProperties: visitorPrefix + "/visitor/properties",
  getFloors: visitorPrefix + "/visitor/floors",
};

// ** ALL GATE-MANAGEMENT RELATED ROUTES **
export const AllGateManagementRoutes = {
  getVisitor: visitorPrefix + "/visitor/get-visitor",
  visitorCount: visitorPrefix + "/visitor/get-visitor-count",
  entryExitLogs: visitorPrefix + "visitor/visitor-checkin-log",
  getGuards: guardPrefix + "guard/get-all-guard",
  visitorType: visitorPrefix + "/visitor/get-visitor-type",
  staffType: staffPrefix + "staff/get-staff-type",
  addVisitorType: visitorPrefix + "/visitor/add-visitor-type",
  AddStaffType: staffPrefix + "staff/add-staff-type",
};

// ** ALL MIS-DASHBOARD RELATED ROUTES **
export const AllMISDashboardRoutes = {
  getMsiData: managementPrefix + "/msi-data",
  misVendorWiseGraphData: managementPrefix + "/msi-vendor-wise-graph-data",
  misVendorPerformanceGraphData:
    managementPrefix + "/management/msi-vendor-performance-data",
  misCategoryWiseGraphData:
    managementPrefix + "/get-category-wise-complaintData",
  misSlaGraphData: managementPrefix + "/get-sla-complaint-data",
  misSectorWiseGraphData: managementPrefix + "/get-sector-complaint-data",
  misQuickViewOtherData: managementPrefix + "/quick-view-data",
  getCategoryWiseComplaintCounts: managementPrefix + "/get-complaints-counts",
  getMostRaisedCategory:
    managementPrefix + "/get-most-raised-complaint-category-count",
  getMostRaisedProperty:
    managementPrefix + "/get-most-raised-complaint-property-count",
  misSlaZoneGraphData: managementPrefix + "/mis-zone-wise-complaint",
  societyWiseComplaintsData: managementPrefix + "/society-wise-complaints-data",
  getMostRaisedArea: managementPrefix + "/getMostRaisedArea",
};
export const AllMISCSVRoutes = {
  getCategoryWiseComplainData: csvPrefix + "/getCategoryWiseComplaintData",
  getSlaComplainData: csvPrefix + "/getSlaComplaintData",
  getZoneWiseComplainData: csvPrefix + "/getZoneWise",
  getSectorWiseComplainData: csvPrefix + "/getSectorWise",
  getVendorWiseComplainData: csvPrefix + "/getVendorWise",
  getCategoryComplainData: csvPrefix + "/getCategoryComplaints",
  getVendorComplaintData: csvPrefix + "/getVendorComplaint",
  getSocietyWiseComplaintData: csvPrefix + "/getsocietyWise",
  getPropertyWiseComplaintData: csvPrefix + "/getpropertyWise",
  getAreaWise: csvPrefix + "/getAreaWise",
  userData: csvPrefix + "/userData",
};

// ** ALL SETTINGS RELATED ROUTES **
export const AllSettingRoutes = {
  serachType: managementPrefix + "/search-Type",
  propertType: managementPrefix + "management/propertyType",
  flatType: managementPrefix + "management/flatType",
  getProperties: visitorPrefix + "/visitor/properties",
  createProperty: managementPrefix + "management/property",
  allFloors: visitorPrefix + "/visitor/floors",
  createFlat: managementPrefix + "management/flat",
  getAllTimeline: timelinePrefix + "/timeline/get-All-timeline",
  userCheck: memberPrefix + "/member/user-check",
  makeManager: userPrefix + "/add-property-manager",
};

// ** ALL GROUP RELATED ROUTES *
export const AllGroupRoutes = {
  addGroup: groupPrefix + "/add-address-group",
  getGroup: groupPrefix + "/get-address-group",
  editGroup: groupPrefix + "/edit-address-group",
};

// ** ALL AREA RELATED ROUTES *
export const AreaRoutes = {
  addArea: managementPrefix + "/add-area",
  getArea: managementPrefix + "/get-area",
  editArea: managementPrefix + "/edit-area",
  addSubArea: managementPrefix + "/add-sub-area",
  getSubArea: managementPrefix + "/get-sub-area",
  editSubArea: managementPrefix + "/edit-sub-area",
  getZone: managementPrefix + "/get-zones",
};
