import React, { useState, useEffect, useContext } from "react";
import { Typography, Paper, Grid, CssBaseline } from "@material-ui/core";
import useStyles from "../../styles";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Checkbox, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PendinglRequeststable from "./PendinglRequeststable";
import { ArrowRightAlt, FindReplace, Group, History } from "@material-ui/icons";
import Loading from "../../../../contextAPI/Loading";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import * as api from "../../../../api";
import moment from "moment";
import { PersonSearch } from "@mui/icons-material";
import { AuthContext } from "../../../../contextAPI/context";
import RejectedRequeststable from "../ApprovedRequests/RejectedRequeststable";
import { resolveErrorMessage } from "../../../../services/service";
import {
  navigateToResident,
  navigateToResidentRequestHistory,
} from "../../../../Navigation/Navigations";
import Chip from "@mui/material/Chip";
import AddressFilterModal from "../../../../GlobalComponents/AddressFilterModal/AddressFilterModal";
import ApiCall from "../../../../api/ApiCall";
import { AllResidentRoutes } from "../../../../api/Routes";
import VendorRequest from "../../../Vendors/ApproveVendor/VendorRequest";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3d3fb8",
  500: "#007FFF",
  600: "#343cac",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
  1000: "#2f45c5",
  1100: "#2e3094",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: ${blue[1100]};
  min-width: 60vw;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);

function PendingRequests() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions, plant } = authState;
  const [selectableHeader, setselectableHeader] = useState("User");
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  var date = moment(new Date()).format("YYYY-MM-DD");
  var newDate = moment(new Date()).add(-3, "months").format("YYYY-MM-DD");
  const [allPendingRequests, setallPendingRequests] = useState([]);
  const [approvedUserCount, setapprovedUserCount] = useState(0);
  const [rejectedUserCount, setrejectedUserCount] = useState(0);
  const [statusForResidentData, setstatusForResidentData] = useState("pending");
  const [isReset, setisReset] = useState(false);
  const [allVendorRequest, setAllVendorRequest] = useState([]);

  // ADDRESS FILTER MODAL
  const [isOpenAddressFilterModal, setisOpenAddressFilterModal] =
    useState(false);
  const [property, setproperty] = useState("Select Property");
  const [floor, setfloor] = useState("Select Floor");
  const [flat, setflat] = useState("Select Flat");
  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const accountHeader = ["User", "Vendor"];

  const handleViewRejectedReq = () => {
    setstatusForResidentData(
      statusForResidentData === "pending" ? "rejected" : "pending"
    );
  };

  const handleChange = (event, newValue) => {
    setPage(1);
    setselectableHeader(newValue);
  };

  const getAllPendingRequests = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : date,
        property: property === "Select Property" ? "" : property?._id,
        floor: floor === "Select Floor" ? "" : floor?._id,
        flat: flat === "Select Flat" ? "" : flat?._id,
        searchKey: search,
        status: statusForResidentData,
        pageNumber: page,
        pageLimit: 500,
      };
      if (selectableHeader === "Manager") {
        sendData.isApproved = false;
      }
      const res = await api.getAllResidentRequests(sendData);
      if (res?.data?.success) {
        setisReset(false);
        setCount(res.data?.user?.count);
        setrejectedUserCount(res.data?.user?.rejectedUser);
        setapprovedUserCount(res.data?.user?.approvedUser);
        setallPendingRequests(res.data?.user?.user);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      console.log(error);
      resolveErrorMessage(error);
    }
  };

  const handleReset = () => {
    setsearch("");
    setStartDate("");
    setEndDate("");
    setPage(1);
    setproperty("Select Property");
    setfloor("Select Floor");
    setflat("Select Flat");
    setisReset(true);
  };

  useEffect(() => {
    if (selectableHeader !== "Vendor") {
      getAllPendingRequests();
    }
  }, [
    page,
    search,
    selectableHeader,
    startDate,
    statusForResidentData,
    isReset,
    property,
    floor,
    flat,
  ]);

  const handleOpenFilterByAddressModal = () => {
    setisOpenAddressFilterModal(true);
  };
  const removeStartDate = () => {
    setStartDate("");
  };
  const removeEndDate = () => {
    setEndDate("");
    getAllPendingRequests();
  };
  const removeProperty = () => {
    setproperty("Select Property");
  };
  const removeFloor = () => {
    setfloor("Select Floor");
  };
  const removeFlat = () => {
    setflat("Select Flat");
  };

  return (
    <>
      <div
        className={classes.root}
        style={{
          opacity: loader ? 0.4 : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} sm={12}>
              <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
                <Grid
                  container
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Grid
                    container
                    item
                    xs={4}
                    lg={4}
                    sm={4}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      Request List&nbsp;&nbsp;
                      {/* <Badge
                        badgeContent={
                          selectableHeader === "Vendor"
                            ? allVendorRequest?.length
                            : allPendingRequests?.length
                        }
                        color={
                          statusForResidentData === "pending"
                            ? "warning"
                            : "error"
                        }
                        style={{ marginTop: -3 }}
                      ></Badge> */}
                      &nbsp;
                      {!loader ? (
                        <RefreshIcon
                          title="refresh request Page"
                          onClick={() => {
                            getAllPendingRequests();
                          }}
                          style={{
                            marginLeft: 5,
                            marginTop: -4,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <LoadingOutlined
                          title="laoding..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                            marginTop: -4,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    lg={8}
                    sm={8}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div class="box" style={{ marginTop: "-5px" }}>
                      <input
                        type="text"
                        placeholder={`${
                          plant ? "Search employee" : "search resident"
                        }`}
                        onChange={(e) => {
                          setsearch(e.target.value);
                          setPage(1);
                        }}
                        value={search}
                      />
                      <div>
                        <SearchIcon />
                      </div>
                    </div>

                    <div
                      className="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "180px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginRight: 5,
                      }}
                      onClick={() => {
                        navigate(navigateToResident({ status: "approved" }));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          className="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          {/* <Badge
                            badgeContent={approvedUserCount}
                            color="success"
                            style={{ marginTop: -3 }}
                          ></Badge> */}
                          <Group
                            style={{
                              fontSize: "18px",
                            }}
                          />
                          &nbsp;{plant ? "EMPLOYEE" : "RESIDENT"} LIST
                          <ArrowRightAlt
                            style={{
                              marginLeft: 5,
                              fontSize: "15px",
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    {/* <ExportJsonCsv
                      fileTitle={`Pending Residents-${fetchSocietyUniqueId()}`}
                      headers={headers}
                      items={csvData}
                      style={{
                        fontSize: "11px",
                        height: 32,
                        width: "160px",
                        boxShadow: "0 3px 6px #4a6078",
                        backgroundColor: "#2f45c5",
                        fontFamily: "Montserrat, sans-serif",
                        color: "#ffffff",
                        borderRadius: 5,
                        marginRight: 6,
                        border: "2px solid #2f45c5",
                        textAlign: "center",
                        marginLeft: 6,
                      }}
                    >
                      <CloudDownloadOutlined
                        style={{ marginRight: 10, fontSize: "15px" }}
                      />
                      DOWNLOAD CSV
                    </ExportJsonCsv> */}
                    <div
                      className="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "120px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(navigateToResidentRequestHistory({}));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          className="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-14px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          HISTORY
                          <History />
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: 15, justifyContent: "space-between" }}
                >
                  <Grid>
                    <Grid container>
                      <div
                        className="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "180px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginLeft: 5,
                          marginRight: 6,
                        }}
                        onClick={handleOpenFilterByAddressModal}
                      >
                        <div class="card-body ">
                          <p
                            className="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-14px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            Filter By Address &nbsp;
                            <PersonSearch />
                          </p>
                        </div>
                      </div>
                      <div
                        className="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "120px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        <div class="card-body ">
                          <p
                            className="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-14px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            RESET
                            <FindReplace />
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid>
                    <Grid container>
                      <Typography
                        style={{
                          color: "#283f59",
                          fontSize: 12,
                          marginRight: 8,
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                          marginTop: 12,
                        }}
                      >
                        Start Date
                      </Typography>
                      <input
                        type="date"
                        style={{
                          borderRadius: 10,
                          padding: 5,
                          width: 120,
                          height: 40,
                          border: "1px solid #416082",
                          outline: 0,
                        }}
                        onChange={(event) => {
                          setPage(1);
                          setStartDate(event.target.value);
                        }}
                        placeholder="Choose Start Date"
                        max={date}
                        value={startDate === "" ? newDate : startDate}
                      />

                      <Typography
                        style={{
                          color: "#283f59",
                          fontSize: 12,
                          marginTop: 12,
                          marginRight: 8,
                          marginLeft: 10,
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        End Date
                      </Typography>
                      <input
                        type="date"
                        style={{
                          borderRadius: 10,
                          padding: 5,
                          width: 120,
                          height: 40,
                          border: "1px solid #416082",
                          outline: 0,
                        }}
                        min={startDate}
                        max={date}
                        disabled={!startDate}
                        onChange={(event) => {
                          setPage(1);
                          setEndDate(event.target.value);
                        }}
                        placeholder="Choose End Date"
                        value={endDate === "" ? date : endDate}
                      />
                      <div
                        className="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "80px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginLeft: 5,
                          marginTop: 3,
                        }}
                        onClick={() => {
                          getAllPendingRequests();
                          setPage(1);
                        }}
                      >
                        <div class="card-body ">
                          <p
                            className="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-14px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            <PersonSearch />
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ justifyContent: "flex-end", marginTop: 10 }}
                >
                  {startDate && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={startDate}
                        onDelete={removeStartDate}
                      />
                    </Typography>
                  )}
                  {endDate && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={endDate}
                        onDelete={removeEndDate}
                      />
                    </Typography>
                  )}
                  {property !== "Select Property" && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={property?.name}
                        onDelete={removeProperty}
                      />
                    </Typography>
                  )}
                  {floor !== "Select Floor" && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={floor?.name}
                        onDelete={removeFloor}
                      />
                    </Typography>
                  )}
                  {flat !== "Select Flat" && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={flat?.name}
                        onDelete={removeFlat}
                      />
                    </Typography>
                  )}
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                  container
                  style={{ marginTop: 18, justifyContent: "center" }}
                >
                  <TabsUnstyled
                    value={selectableHeader}
                    onChange={handleChange}
                  >
                    <TabsList value={selectableHeader}>
                      {accountHeader?.map((tb) => {
                        return <Tab value={tb}>{tb}</Tab>;
                      })}
                    </TabsList>
                  </TabsUnstyled>
                </Grid> */}
                <Grid container style={{ justifyContent: "flex-end" }}>
                  <Checkbox
                    checked={statusForResidentData === "rejected"}
                    onClick={handleViewRejectedReq}
                    style={{ color: "#283f59", marginTop: 7 }}
                  />
                  &nbsp;
                  <Typography
                    style={{
                      color: "#283f59",
                      fontSize: 14,
                      marginTop: 10,
                      marginRight: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    View Rejected Request
                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge
                      badgeContent={rejectedUserCount}
                      color={"error"}
                      style={{ marginTop: -2 }}
                    ></Badge> */}
                  </Typography>
                </Grid>
                {selectableHeader !== "Vendor" && (
                  <>
                    {allPendingRequests?.length > 0 && (
                      <Grid container style={{ marginTop: 7 }}>
                        {statusForResidentData === "pending" ? (
                          <PendinglRequeststable
                            allPendingRequests={allPendingRequests}
                            page={page}
                            getAllPendingRequests={getAllPendingRequests}
                            user={user}
                            usermoduleswithpermissions={
                              usermoduleswithpermissions
                            }
                            selectableHeader={selectableHeader}
                            plant={plant}
                          />
                        ) : (
                          <RejectedRequeststable
                            allRejectedRequests={allPendingRequests}
                            page={page}
                            getAllPendingRequests={getAllPendingRequests}
                            user={user}
                            usermoduleswithpermissions={
                              usermoduleswithpermissions
                            }
                            plant={plant}
                          />
                        )}
                      </Grid>
                    )}
                  </>
                )}

                {allPendingRequests?.length > 0 && (
                  <Grid
                    container
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <Pagination
                      className={classes.pagination}
                      count={count}
                      color="primary"
                      page={page}
                      onChange={handlePageChange}
                    />
                  </Grid>
                )}

                {!loader && allPendingRequests?.length === 0 && (
                  <Grid container style={{ justifyContent: "center" }}>
                    <Typography
                      style={{
                        color: "#6c757d",
                        textAlign: "center",
                        fontSize: 22,
                        marginTop: 30,
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {statusForResidentData === "pending"
                        ? selectableHeader === "Manager"
                          ? "No Pending Manager"
                          : "No Pending Residents"
                        : selectableHeader === "Manager"
                        ? "No Rejected Manager"
                        : "No Rejected Residents"}
                    </Typography>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
      {loader && <Loading loader={loader} />}
      <AddressFilterModal
        isSector={false}
        isLocal={false}
        isOpenAddressFilterModal={isOpenAddressFilterModal}
        setisOpenAddressFilterModal={setisOpenAddressFilterModal}
        property={property}
        setproperty={setproperty}
        floor={floor}
        setfloor={setfloor}
        flat={flat}
        setflat={setflat}
        handleFilterByAddress={getAllPendingRequests}
      />
    </>
  );
}

export default PendingRequests;
